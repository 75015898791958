import React from 'react';
import { AvatarGroup, Grid, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/system';
import { grey } from '@mui/material/colors';
import { IGroupWithMessage } from '../../../services/crm/types';
import Typography from '@mui/material/Typography';
import { DateTimeAgo } from '../../DateTimeAgo';
import { convertDate } from '../../../helpers/date';

type Props = {
  group: IGroupWithMessage;
}

// const convertDate = (date: any) => {
//   console.log('convert date -> ', date);
//   const d = new Date(`${date} UTC`);
//   return new Date(d);
// }

const messageStyles = {
  "&:hover": {
    backgroundColor: grey[900],
  },
  paddingLeft: 1,
  paddingRight: 1,
};

const truncate = (s: string, max: number) => {
  if (s.length > max) {
    return s.substring(0, max) + "...";
  }
  return s;
};

export const Group = ({ group }: Props) => {
  return (
    // <Stack direction="row" spacing={2} sx={messageStyles}>
    <Grid container spacing={2} sx={{ cursor: 'pointer', paddingLeft: 3, paddingTop: 2 }} columns={12}>
      <Grid item xs={1}></Grid>
      <Grid item xs={1} sx={{ alignItems: 'left'}}>
        <AvatarGroup max={2}>
          {group.users.map(user => <Avatar key={user.id} sx={{width: '18px', height: '18px'}} alt={user.firstName} src={user.avatar} />)}
        </AvatarGroup>
      </Grid>
      <Grid item xs={10}>
        <Stack direction={"column"} spacing={0}>
          <Box>
            {group.unread ? (
              <strong style={{ color: '#ffffff', fontSize: '1.1rem'}}>{group.title}</strong>
            ) : (
              <span style={{ color: grey[200], fontSize: '1.1rem'}}>{group.title}</span>
            )}
            {group.lastMessage ? (
              <small style={{ marginLeft: '10px' }}><DateTimeAgo date={convertDate(group.lastMessage.createdAt)} /></small>
            ) : ''}
          </Box>
          <Box><Typography sx={{color: grey[400]}}>
            {group.lastMessage ? (group.lastMessage.recording ? '[Shared a clip]' : truncate(group.lastMessage.message, 60)) : 'No message'}
          </Typography></Box>
        </Stack>
      </Grid>
    </Grid>
  )
}
