import { color } from './color';

export const itsonTheme = {
  mode: 'dark',
  color,
  fontFamily: {
    default: '"Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  fontSize: {
    tiny: '0.625rem',
    extraSmall: '0.75rem',
    small: '0.875rem',
    base: '1rem',
    medium: '1.25rem',
    large: '1.5rem',
    extraLarge: '2.25rem',
  },
  lineHeight: {
    low: 1.2,
    high: 1.5,
  },
  border: {
    outline: {
      default: {
        width: 1,
        style: 'solid',
        color: '#E0E0E0',
      },
    },
    radius: {
      default: 4,
    },
  },
  boxShadow: {
    default: '0px 0px 4px rgba(0, 0, 0, 0.06), 0px 2px 5px rgba(0, 0, 0, 0.15)',
  },
  breakpoint: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  spacing: {
    tiny: 2,
    extraSmall: 4,
    small: 6,
    medium: 12,
    large: 24,
    extraLarge: 36,
  },
};

export type ITSONTheme = typeof itsonTheme;
