import { useState } from "react";
import { 
  Stack,
  Typography,
  Switch,
  Menu,
  MenuItem,
  IconButton,
  ClickAwayListener,
  Divider,
  MenuList,
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { IStream } from '../../services/api/types';
import { useStreams } from '../../hooks/useStreams';
import { sx } from './styles';

interface Props {
    streams: IStream[];
}

export function Filters ({ streams }: Props) {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const activeStreamsSwitch = useStreams();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const toggleStream = (stream: IStream) => {
    activeStreamsSwitch.toggleStream(stream);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Stack>
          <IconButton 
              onClick={handleOpen}
              disabled={!streams.length}
          >
              <FilterAltIcon />
          </IconButton>
          <Menu
              open={openMenu}
              anchorEl={anchorEl}
              onClose={handleClose}
              sx={sx.menu}
              disableAutoFocusItem
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
              }}
          > 
              <MenuList dense>
                <MenuItem sx={sx.menuTitle}>CAMARAS</MenuItem>
                <Divider />
                {streams.map(stream =>
                    <MenuItem
                        key={stream.streamId}
                        onClick={() => toggleStream(stream)}
                    >
                        <Typography>
                            {stream.title}
                        </Typography>
                        <Switch
                            onClick={() => toggleStream(stream)}
                            checked={activeStreamsSwitch.activeStreams[stream.id]}
                            size="medium"
                        />
                    </MenuItem>
                )}
              </MenuList>
          </Menu>
        </Stack>
    </ClickAwayListener>
  )
}
