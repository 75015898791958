import { Alert, Box, CircularProgress, Grid, ImageList, Paper } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { IRecording } from '../../services/api/types';
import { PlaybackVideo } from '../../components/PlaybackVideo';
import { Paginator } from '../../components/Paginator';
import { CrmApiClient, CrmApiClientFactory } from '../../services/crm/client';
import { NavigateFunction, Location, useLocation, useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import { yellow } from '@mui/material/colors';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import { useWidth } from '../../hooks/useWidth';
import { UseStreams, useStreams } from '../../hooks/useStreams';
import { itsonMuiTheme } from '../../theme/ITSONMUITheme';

type State = {
  recordings: IRecording[];
  loadingRecordingsError: boolean;
  currentPage: number;
  isExpanded: boolean;
  isLoading: boolean;
}

type Props = {
  navigate: NavigateFunction;
  location: Location;
  isExpanded: boolean;
  breakpoint: string;
  activeStreamsSwitch: UseStreams;
}

class RecordingsComponent extends Component<Props, State> {

  crmApiClient: CrmApiClient;
  pageSize = 12;

  constructor (props: Props) {
    super(props);
    const searchParams = new URLSearchParams(location.search);

    this.state = {
      recordings: [],
      loadingRecordingsError: false,
      currentPage: +(searchParams.get('rpage') || 1),
      isExpanded: this.props.isExpanded,
      isLoading: true,
    };
    this.crmApiClient = CrmApiClientFactory(props.navigate);
  }

  componentDidMount () {
    this.crmApiClient.getRecordings()
      .then((recordings: IRecording[]) => {
        const sortedRecordings = recordings.sort((r1: IRecording, r2: IRecording): number => {
          const d1 = new Date(r1.created);
          const d2 = new Date(r2.created);
          return d2.valueOf() - d1.valueOf();
        })
        this.setState({
          ...this.state,
          recordings: sortedRecordings,
          loadingRecordingsError: false,
          isLoading: false,
        });
      })
      .catch((error: any) => {
        this.setState({
          ...this.state,
          recordings: [],
          loadingRecordingsError: true,
          currentPage: 1,
          isLoading: false,
        });
        this.storePage(1);
      });

    const bodyEl = document.getElementsByTagName('body');
    if (bodyEl !== null) {
      bodyEl[0].classList.remove('overflow-hidden');
    }
  }

  paginate (recordings: IRecording[], page: number): IRecording[] {
    if (!recordings) {
      return [];
    }
    return [...recordings].slice((page - 1) * this.pageSize, page * this.pageSize);
  }

  onPageChange (page: number) {
    this.setState({
      ...this.state,
      currentPage: page,
    });
    this.storePage(page);
  }

  storePage (page: number) {
    const url = new URL(window.location.href);
    url.searchParams.set("rpage", `${page}`);
    window.history.pushState(null, '', url.toString());
  }

  render () {
    const recordings = this.state.recordings ? this.state.recordings.filter(recording => {
      return this.props.activeStreamsSwitch.activeStreams[recording.stream.id];
    }) : [];
    let cols = 4;
    switch (this.props.breakpoint) {
      case 'xs':
        cols = 1;
        break;
      case 'sm':
        cols = 2;
        break;
      default:
        cols = 4;
        break;
    }
    let content;
    if (this.state.isLoading) {
      content = (
        <Box sx={{ display: 'flex', paddingTop: 1, paddingBottom: 2 }}><CircularProgress sx={{margin: '0 auto', marginTop: 1}} /></Box>
      )
    } else if (this.state.loadingRecordingsError) {
      content = (
        <Grid sx={{ paddingTop: 2 }} item xs={12}>
          <Alert variant="outlined" severity="error">
            Could not load clips
          </Alert>
        </Grid>
      );
    } else if (!recordings || recordings.length === 0) {
      content = (
        <Grid sx={{ padding: 2 }} item xs={12}>
          <Typography color={itsonMuiTheme.palette.primary.main}>
            No clips found
          </Typography>
        </Grid>
      );
    } else {
      content = (
        <Box sx={{marginTop: '80px', padding: 0, marginBottom: 4}}>
          <ImageList cols={cols} sx={{marginTop: 0, marginBottom: 4, overflow: 'hidden'}}>
            {this.paginate(recordings, this.state.currentPage).map((recording: IRecording) =>
              <PlaybackVideo key={recording.url} recording={recording}/>
            )}
          </ImageList>
          <Paginator
            count={recordings.length}
            pageSize={this.pageSize}
            currentPage={this.state.currentPage}
            onPageChange={this.onPageChange.bind(this)} />
        </Box>
      )
    }
    return (
      <Box sx={{ paddingLeft: '5px', paddingRight: '5px' }}>
        { content }
      </Box>
    )
  }
}

export const Recordings = (props: any) => {
  const activeStreamsSwitch = useStreams();
  const navigate = useNavigate()
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const breakpoint = useWidth();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('page') === 'playback') {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [location.search])
  return (<RecordingsComponent {...props}
            activeStreamsSwitch={activeStreamsSwitch}
            breakpoint={breakpoint}
            isExpanded={isExpanded}
            navigate={navigate}/>)
}
