import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from '../pages/Layout';
import { Login } from '../pages/Login';
import { Recordings } from '../pages/Recordings';
import { PrivateRoute } from '../components/PrivateRoute';
import { LiveStreams } from '../pages/LiveStreams';
import { Photos } from '../pages/Photos';
import { Dashboard } from '../pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import * as React from 'react';

export function AppRoutes () {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="dark"
      />
      <Routes>
        <Route path="/" element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }>
          <Route index element={<LiveStreams />} />
          <Route path={"/live"} element={<LiveStreams />} />
          <Route path={"/clips"} element={<Recordings />} />
          <Route path={"/photos"} element={<Photos />} />
        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
}
