import ReactTimeAgo from 'react-time-ago';
import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en);

type Props = {
  date: any;
  tooltip?: boolean;
}

export const DateTimeAgo = (props: Props) => {
  try {
    if (!props.date || props.date.indexOf('Invalid') > -1) {
      throw new Error('invalid date');
    }
    return (
      <ReactTimeAgo date={props.date} tooltip={props.tooltip} locale={'en-US'} />
    )
  } catch (e) {
    return (<span></span>)
  }
}
