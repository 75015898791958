import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/http-streaming';
import './plugins/videojs-webrtc-plugin/plugin';
// import '@antmedia/webrtc_adaptor';
// import '@antmedia/videojs-webrtc-plugin';
import '@theonlyducks/videojs-zoom/styles';
import '@theonlyducks/videojs-zoom';

export const VideoJS = (props: any) => {
  const videoRef: any = React.useRef(null);
  const playerRef: any = React.useRef(null);
  const {options, onReady} = props;

  let player: any;
  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });
      if (options.width) {
        player.width(options.width);
      }
      player.muted(options.muted || true);

    } else {
      player = playerRef.current;

      if (options.width) {
        player.width(options.width);
      }
      player.muted(options.muted || true);
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player: any = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  console.log('videojs.options', options);
  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
}

export default VideoJS;
