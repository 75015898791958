import { Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/system';
import { grey } from '@mui/material/colors';
import { IUser } from '../../../services/crm/types';

type Props = {
  user: IUser;
}

const messageStyles = {
  "&:hover": {
    backgroundColor: grey[900],
  },
  padding: 0,
  paddingLeft: 2,
  cursor: 'pointer',
};

export const ActiveUser = ({ user }: Props) => {
  return (
    <Stack direction="row" spacing={2} sx={messageStyles}>
      <Avatar alt="Remy Sharp" src={user.avatar} />
      <Stack direction={"column"} spacing={1} sx={{ paddingTop: 1}}>
        <Box justifyContent={'center'}><span style={{ color: grey[200]}}>{ user.firstName } { user.lastName }</span></Box>
      </Stack>
    </Stack>
  )
}
