import { IStream } from '../api/types';
import { CrmApiClient } from '../crm/client';
import memoize from 'memoizee';

export class Streams {

  memoized: () => Promise<IStream[]>;

  constructor (private apiClient: CrmApiClient) {
    this.memoized = memoize(this.getter, { promise: true });
  }

  getter () {
    // return Promise.resolve([
    //   {
    //     rtmpURL: 'http://playertest.longtailvideo.com/adaptive/wowzaid3/playlist.m3u8',
    //     id: '7',
    //     title: 'D',
    //     name: 'wowza',
    //     isRecording: false,
    //     orientation: 'landscape',
    //     streamId: '2',
    //     canRecording: true,
    //     description: 'wowza',
    //     mp4Enabled: -1,
    //     status: 'active',
    //     type: 'm3u8',
    //   },
    // {
    //   rtmpURL: 'https://itson-recordings.s3.eu-west-1.amazonaws.com/streams/mixkit-waves-in-the-water-1164.mp4',
    //   id: '4',
    //   title: 'A',
    //   name: 'vm7',
    //   isRecording: false,
    //   orientation: 'portrait',
    //   streamId: '4',
    //   canRecording: true,
    //   description: 'hls',
    //   mp4Enabled: -1,
    //   status: 'active',
    //   type: 'mp4',
    // },
    // {
    //   rtmpURL: 'https://itson-recordings.s3.eu-west-1.amazonaws.com/streams/mixkit-waves-in-the-water-1164.mp4',
    //   id: '5',
    //   title: 'B',
    //   name: 'vm7',
    //   isRecording: false,
    //   orientation: 'portrait',
    //   streamId: '5',
    //   canRecording: true,
    //   description: 'hls',
    //   mp4Enabled: -1,
    //   status: 'active',
    //   type: 'mp4',
    // },
    // {
    //   rtmpURL: 'https://itson-recordings.s3.eu-west-1.amazonaws.com/streams/mixkit-waves-in-the-water-1164.mp4',
    //   id: '6',
    //   title: 'D',
    //   name: 'vm7',
    //   isRecording: false,
    //   orientation: 'portrait',
    //   streamId: '6',
    //   canRecording: true,
    //   description: 'hls',
    //   mp4Enabled: -1,
    //   status: 'active',
    //   type: 'mp4',
    // },
    //   {
    //     rtmpURL: 'https://itson-recordings.s3.eu-west-1.amazonaws.com/streams/mixkit-waves-in-the-water-1164.mp4',
    //     id: '7',
    //     title: 'E',
    //     name: 'vm7',
    //     isRecording: false,
    //     orientation: 'portrait',
    //     streamId: '7',
    //     canRecording: true,
    //     description: 'hls',
    //     mp4Enabled: -1,
    //     status: 'active',
    //     type: 'mp4',
    //   },
    // {
    //   rtmpURL: 'http://qthttp.apple.com.edgesuite.net/1010qwoeiuryfg/sl.m3u8',
    //   id: '3',
    //   title: 'C',
    //   name: 'hls',
    //   isRecording: false,
    //   orientation: 'landscape',
    //   streamId: '3',
    //   canRecording: true,
    //   description: 'hls',
    //   mp4Enabled: -1,
    //   status: 'active',
    //   type: 'm3u8',
    // },
    // {
    //   rtmpURL: 'http://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8',
    //   id: '1',
    //   title: 'A',
    //   name: 'skate',
    //   isRecording: false,
    //   orientation: 'landscape',
    //   streamId: '1',
    //   canRecording: true,
    //   description: 'skate',
    //   mp4Enabled: -1,
    //   status: 'active',
    //   type: 'm3u8',
    // },
    // {
    //   rtmpURL: 'http://playertest.longtailvideo.com/adaptive/wowzaid3/playlist.m3u8',
    //   id: '2',
    //   title: 'C',
    //   name: 'wowza',
    //   isRecording: false,
    //   orientation: 'landscape',
    //   streamId: '2',
    //   canRecording: true,
    //   description: 'wowza',
    //   mp4Enabled: -1,
    //   status: 'active',
    //   type: 'm3u8',
    // },
    // {
    //   rtmpURL: 'http://playertest.longtailvideo.com/adaptive/wowzaid3/playlist.m3u8',
    //   id: '7',
    //   title: 'D',
    //   name: 'wowza',
    //   isRecording: false,
    //   orientation: 'landscape',
    //   streamId: '2',
    //   canRecording: true,
    //   description: 'wowza',
    //   mp4Enabled: -1,
    //   status: 'active',
    //   type: 'm3u8',
    // },
    // ]);
    return this.apiClient.getStreams();
  }

  async fetch (force = false): Promise<IStream[]> {
    if (force) {
      return this.getter();
    }
    return this.memoized();
  }
}
