import { Alert, Box, CircularProgress, Grid } from '@mui/material';
import { LiveVideo } from '../../components/LiveVideo';
import React, { Component, useEffect, useState } from 'react';
import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { CrmApiClient, CrmApiClientFactory } from '../../services/crm/client';
import { Streams } from '../../services/streams/streams';
import { IStream } from '../../services/api/types';
import { UseStreams, useStreams } from '../../hooks/useStreams';
import { useWidth } from '../../hooks/useWidth';
import { NoStream } from '../../components/NoStream/NoStream';

type State = {
  streams: IStream[];
  streamsMap: {[id: string]: IStream};
  loadingStreamsError: boolean;
  isLoading: boolean;
  canRecording: boolean;
  isExpanded: boolean;
  isDialogOpened: boolean;
}

type Props = {
  navigate: NavigateFunction;
  location: Location;
  isExpanded: boolean;
  activeStreamsSwitch: UseStreams;
  breakpoint: string;
}

class LiveStreamsComponent extends Component<Props, State> {

  crmApiClient: CrmApiClient;
  streamsHelper: Streams;

  constructor (props: Props) {
    super(props);
    this.state = {
      streams: [],
      streamsMap: {},
      loadingStreamsError: false,
      canRecording: false,
      isExpanded: this.props.isExpanded,
      isDialogOpened: false,
      isLoading: true,
    };
    this.crmApiClient = CrmApiClientFactory(this.props.navigate);
    this.streamsHelper = new Streams(this.crmApiClient);
  }

  componentDidMount () {
    this.streamsHelper.fetch()
      .then((streams: IStream[]) => {
        this.setState({
          ...this.state,
          streams: streams,
          streamsMap: Object.assign({}, ...streams.map((stream: IStream) => { return {[stream.id]: stream}; })),
          loadingStreamsError: false,
          isLoading: false,
        });
      })
      .catch((error: any) => {
        this.setState({
          ...this.state,
          streams: [],
          streamsMap: {},
          loadingStreamsError: true,
          isLoading: false,
        });
      });

    if (this.props.breakpoint === 'md' || this.props.breakpoint === 'lg' || this.props.breakpoint === 'xl') {
      const bodyEl = document.getElementsByTagName('body');
      if (bodyEl !== null) {
        //bodyEl[0].classList.add('overflow-hidden');
      }
    }

  }

  handleDialogClose () {
    this.setState({
      ...this.state,
      isDialogOpened: false,
    })
  }

  openCameraDialog () {
    this.setState({
      ...this.state,
      isDialogOpened: true,
    })
  }

  render () {
    // let paddingLeft = 0, paddingRight = 0;
    let appBarHeight = 64;
    const appBar = document.getElementById('app-bar');
    if (appBar !== null) {
      appBarHeight = appBar.clientHeight;
    }
    const sh = window.innerHeight;
    const sw = window.innerWidth;
    const vh = sh - appBarHeight - 10;
    const vw = (sw/sh) * (vh);

    const pt = sw - ((1920 * ((vh) / (1080 * 2))) * 2);
    // paddingRight = paddingLeft = (pt / 2);
    const width = sw - pt;
    const aliveStreamsCount = this.state.streams.filter(stream => {
      return stream.isAlive;
    }).length;
    const streamsCount = this.state.streams.filter(stream => {
      return stream.isAlive && this.props.activeStreamsSwitch.activeStreams[stream.id];
    }).length;

    const layout = this.state.streams.filter((stream: IStream) => {
      return stream.isAlive && this.props.activeStreamsSwitch.activeStreams[stream.id];
    }).map((stream: IStream) => {
      return stream.orientation[0].toUpperCase();
    }).join('');

    if (this.state.isLoading) {
      return (
        <Box sx={{ display: 'flex', paddingTop: 1, paddingBottom: 2 }}><CircularProgress sx={{margin: '0 auto', marginTop: 1}} /></Box>
      )
    } else if (this.state.loadingStreamsError) {
      return (
        <Grid sx={{ paddingTop: 2 }} item xs={12}>
          <Alert variant="outlined" severity="error">
            Could not load live streams
          </Alert>
        </Grid>
      );
    } else if (aliveStreamsCount === 0) {
      return (
        // <Grid sx={{ padding: 2 }} item xs={12}>
        //   <Typography color={itsonMuiTheme.palette.primary.main}>
        //     No stream available at the moment, please wait.
        //   </Typography>
        // </Grid>
        <NoStream />
      );
    } else {
      const liveVideos = this.state.streams.filter(s => s.isAlive && s.streamId).map((stream: IStream, index: number) => this.props.activeStreamsSwitch.activeStreams[stream.id] ? (
        <div key={index} style={{ marginTop: `${this.props.breakpoint === 'xs' && index > 0 ? '5px' : '0px'}`}}>
          <LiveVideo
              navigate={this.props.navigate}
              stream={stream}
              index={index}
              streams={this.props.activeStreamsSwitch.activeStreams} />
        </div>
      ) : '' );

      const videos = liveVideos.filter(lv => lv);

      if (layout === 'LL') {
        if (this.props.breakpoint === 'xs') {
          const marginTop = (vh - (sw * 9/16)) / 4;
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ marginTop: `${marginTop}px`, width: '100%', paddingRight: '5px', paddingLeft: '5px'}}>
                { videos }
              </Box>
            </Box>
          )
        } else {
          const paddingRight = 5;//(pt / 2);
          const paddingLeft = 5;//(pt / 2);
          const marginTop = (vh - (sw / 2 * 9 / 16)) / 2;
          const videos = liveVideos.filter(lv => lv);
          return (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{ marginTop: `${marginTop}px`, width: '50%', marginRight: '5px', paddingLeft: `${paddingLeft}px` }}>
                {videos[0]}
              </Box>
              <Box sx={{ marginTop: `${marginTop}px`, width: '50%', paddingRight: `${paddingRight}px` }}>
                {videos[1]}
              </Box>
            </Box>
          )
        }
      }

      if (layout === 'LP' && this.props.breakpoint !== 'xs') {
        const marginTop = 0;
        const pw = ((vh+10) / 1920) * 1080;
        const lw = sw - (pw);
        const lh = lw * 9/16;
        const paddingRight = 5;
        const paddingLeft = 5;
        // const lmTop = ((vh+5) - (lh*2)) / 2;
        let lHeight = lw * 9/16;
        let lWidth = lw;
        if (lHeight > vh) {
          lHeight = vh;
          lWidth = lHeight * 16/9;
          lHeight = lWidth / (16/9);
        }
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '5px' }}>
            <Box sx={{ marginTop: `${this.props.breakpoint === 'sm' ? '5px' : '0'}`, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: `${lWidth}px`, marginLeft: `${paddingLeft}px`,  paddingRight: `${paddingRight}px` }}>
              <Box sx={{ width: '100%' }}>
                {videos[0]}
              </Box>
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px'}}>
              {videos[1]}
            </Box>
          </Box>
        )
      }

      if (layout === 'L') {
        const pt = sw - ((vh+5) * 16/9);
        let paddingRight = (pt / 2);
        let paddingLeft = (pt / 2);
        let marginTop = 0;
        if (this.props.breakpoint === 'xs') {
          paddingRight = 5;
          paddingLeft = 5;
          marginTop = (vh - (sw * 9/16)) / 2;
        }
        const width = sw;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingRight: `${paddingRight}px`, paddingLeft: `${paddingLeft}px` }}>
            <Box sx={{ marginTop: `${marginTop}px`, width: `${width}px` }}>
              {videos[0]}
            </Box>
          </Box>
        )
      }

      if (layout === 'P') {
        const marginTop = 0;//(vh - (sw/2 * 9/16))/4;
        const width = (vh+5) * 9/16;
        const height = width * 16/9;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}`, width: `${width}px` }}>
              {videos[0]}
            </Box>
          </Box>
        )
      }

      if (layout === 'PP' && this.props.breakpoint !== 'xs') {
        const pt = sw - ((1080 * ((vh+8) / (1920))) * 2);
        const paddingRight = (pt / 2);
        const paddingLeft = (pt / 2);
        const marginTop = 0;//(vh - (sw/2 * 9/16))/4;
        const videos = liveVideos.filter(lv => lv);
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ marginTop: `${marginTop}px`, width: '50%', marginRight: '5px', paddingLeft: `${paddingLeft}px` }}>
              {videos[0]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`,width: '50%', paddingRight: `${paddingRight}px`  }}>
              {videos[1]}
            </Box>
          </Box>
        )
      }

      if (layout === 'PL' && this.props.breakpoint !== 'xs') {
        const marginTop = 0;
        const pw = ((vh+10) / 1920) * 1080;
        const lw = sw - (pw);
        const lh = lw * 9/16;
        const paddingRight = 5;
        const paddingLeft = 5;
        // const lmTop = ((vh+5) - (lh*2)) / 2;
        let lHeight = lw * 9/16;
        let lWidth = lw;
        if (lHeight > vh) {
          lHeight = vh;
          lWidth = lHeight * 16/9;
          lHeight = lWidth / (16/9);
        }
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '5px' }}>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginLeft: `${paddingLeft}px`, marginRight: '5px'}}>
              {videos[0]}
            </Box>
            <Box sx={{ marginTop: `${this.props.breakpoint === 'sm' ? '5px' : '0'}`, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: `${lWidth}px`, paddingRight: `${paddingRight}px`  }}>
              <Box sx={{ width: '100%' }}>
                {videos[1]}
              </Box>
            </Box>
          </Box>
        )
      }
      if (layout === 'PLL' && this.props.breakpoint !== 'xs') {
        const marginTop = 0;
        const pw = ((vh+5) / 1920) * 1080;
        const lw = sw - (pw);
        const lh = lw * 9/16;
        const paddingRight = 5;
        const paddingLeft = 5;
        let lHeight = lw * 9/16;
        let lWidth = lw;
        if (lHeight*2 > vh) {
          lHeight = vh;
          lWidth = lHeight * 16/9 / 2;
          lHeight = lWidth / (16/9);
        }
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '5px' }}>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginLeft: `${paddingLeft}px`, marginRight: '5px'}}>
              {videos[0]}
            </Box>
            <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: `${lWidth}px`, paddingRight: `${paddingRight}px`  }}>
              <Box sx={{ width: '100%', marginTop: '3px' }}>
                {videos[1]}
              </Box>
              <Box sx={{ width: '100%', marginTop: '5px'}}>
                {videos[2]}
              </Box>
            </Box>
          </Box>
        )
      }
      if (layout === 'PPLL' && this.props.breakpoint !== 'xs') {
        const marginTop = 0; //gridLayout.marginTop();
        const pw = ((vh+15) / 1920) * 1080;
        const lw = sw - (pw*2);
        const lh = vh / 2;
        const paddingRight = 5;
        const paddingLeft = 5;
        let lHeight = lw * 9/16;
        let lWidth = lw;
        if (lHeight*2 > vh) {
          lHeight = vh;
          lWidth = lHeight * 16/9 / 2;
          lHeight = lWidth / (16/9);
        }
        // const lmTop = ((vh-10) - (lHeight * 2));
        const videos = liveVideos.filter(lv => lv);
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px', marginLeft: '5px' }}>
              {videos[0]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px' }}>
              {videos[1]}
            </Box>
            <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', width: `${lWidth}px`, marginRight: `${paddingRight}px`  }}>
              <Box sx={{ width: '100%' }}>
                {videos[2]}
              </Box>
              <Box sx={{ width: '100%', marginTop: '5px'}}>
                {videos[3]}
              </Box>
            </Box>
          </Box>
        )
      }

      if (layout === 'PPL' && this.props.breakpoint !== 'xs') {
        const marginTop = 0;
        const pw = ((vh+10) / 1920) * 1080;
        const lw = sw - (pw*2);
        const lh = lw * 9/16;
        const paddingRight = 5;
        const paddingLeft = 5;
        const lmTop = ((vh+10) - (lh)) / 2;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px', marginLeft: '5px' }}>
              {videos[0]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px' }}>
              {videos[1]}
            </Box>
            <Box sx={{ marginTop: `${lmTop}px`, height: `${lh}px`, width: `${lw}px`, paddingRight: `${paddingRight}px`  }}>
              <Box>
                {videos[2]}
              </Box>
            </Box>
          </Box>
        )
      }

      if (layout === 'LPP' && this.props.breakpoint !== 'xs') {
        const marginTop = 0;
        const pw = ((vh+10) / 1920) * 1080;
        const lw = sw - (pw*2);
        const lh = lw * 9/16;
        const paddingRight = 5;
        const paddingLeft = 5;
        const lmTop = ((vh+10) - (lh)) / 2;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ marginTop: `${lmTop}px`, height: `${lh}px`, width: `${lw}px`, marginLeft: '5px', marginRight: '5px' }}>
              <Box>
                {videos[0]}
              </Box>
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px' }}>
              {videos[1]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px' }}>
              {videos[2]}
            </Box>
          </Box>
        )
      }

      if (layout === 'LPPP' && this.props.breakpoint !== 'xs') {
        const marginTop = 0;
        const pw = ((vh+10) / 1920) * 1080;
        const lw = sw - (pw*3);
        const lh = lw * 9/16;
        const paddingRight = 5;
        const paddingLeft = 5;
        const lmTop = ((vh+10) - (lh)) / 2;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ marginTop: `${lmTop}px`, height: `${lh}px`, width: `${lw}px`, marginLeft: '5px', marginRight: '5px' }}>
              <Box>
                {videos[0]}
              </Box>
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px' }}>
              {videos[1]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px' }}>
              {videos[2]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${vh}px`, width: `${pw}px`, marginRight: '5px' }}>
              {videos[3]}
            </Box>
          </Box>
        )
      }

      if (layout === 'PPP' && this.props.breakpoint !== 'xs') {
        const marginTop = 0; // gridLayout.marginTop();
        const width = (vh+2) * 9/16;
        const height = vh;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}px`, width: `${width}px`, marginRight: '5px' }}>
              {videos[0]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}px`, width: `${width}px`, marginRight: '5px' }}>
              {videos[1]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}px`, width: `${width}px` }}>
              {videos[2]}
            </Box>
          </Box>
        )
      }

      if (layout === 'LLL' && this.props.breakpoint !== 'xs') {
        const pt = sw - ((1920 * ((vh+10) / (1080 * 2))) * 2);
        const paddingRight = (pt / 2);
        const paddingLeft = (pt / 2);
        return (
          <Grid container spacing={'5px'} sx={{ paddingRight: `${paddingRight}px`, paddingLeft: `${paddingLeft}px` }}>
            <Grid item md={6} xs={12}>
              {videos[0]}
            </Grid>
            <Grid item md={6} xs={12}>
              {videos[1]}
            </Grid>
            <Grid item md={3} xs={0}></Grid>
            <Grid item md={6} xs={12}>
              {videos[2]}
            </Grid>
          </Grid>
        );
      }

      if (layout === 'LLLL' && this.props.breakpoint !== 'xs') {
        const pt = sw - ((1920 * ((vh+10) / (1080 * 2))) * 2);
        const paddingRight = (pt / 2);
        const paddingLeft = (pt / 2);
        return (
          <Grid container spacing={'5px'} sx={{ paddingRight: `${paddingRight}px`, paddingLeft: `${paddingLeft}px` }}>
            <Grid item md={6} xs={12}>
              {videos[0]}
            </Grid>
            <Grid item md={6} xs={12}>
              {videos[1]}
            </Grid>
            <Grid item md={6} xs={12}>
              {videos[2]}
            </Grid>
            <Grid item md={6} xs={12}>
              {videos[3]}
            </Grid>
          </Grid>
        );
      }

      if (layout === 'PPPP' && this.props.breakpoint !== 'xs') {
        const marginTop = 0;
        const width = vh * 9/16;
        const height = vh;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}px`, width: `${width}px`, marginRight: '5px', marginLeft: '5px' }}>
              {videos[0]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}px`, width: `${width}px`, marginRight: '5px' }}>
              {videos[1]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}px`, width: `${width}px`, marginRight: '5px' }}>
              {videos[2]}
            </Box>
            <Box sx={{ marginTop: `${marginTop}px`, height: `${height}px`, width: `${width}px`, marginRight: '5px' }}>
              {videos[3]}
            </Box>
          </Box>
        )
      }

      if (streamsCount === 1) {
        const pt = sw - ((1920 * (vh / (1080 * 2))) * 2);
        const paddingRight = (pt / 2);
        const paddingLeft = (pt / 2);
        const videos = liveVideos.filter(lv => lv);
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: `${paddingLeft}px`, paddingRight: `${paddingRight}px` }}>
            <Box sx={{ width: '100%'}}>
              {videos}
            </Box>
          </Box>
        )
      }

      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '100%', paddingRight: '5px', paddingLeft: '5px'}}>
            { videos }
          </Box>
        </Box>
      )
    }
    return (
      <Box></Box>
    )
  }
}

export const LiveStreams = (props: any) => {
  const activeStreamsSwitch = useStreams();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const breakpoint = useWidth();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('page') === 'live') { //} || !params.get('page')) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [location.search])
  const navigate = useNavigate();
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  useEffect(() => {
    const debouncedHandleResize = function handleResize () {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    };
  });

  return (<LiveStreamsComponent {...props}
    dimensions={dimensions}
    activeStreamsSwitch={activeStreamsSwitch}
    breakpoint={breakpoint}
    isExpanded={isExpanded}
    location={location}
    navigate={navigate}/>)
}
