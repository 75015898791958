import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Stack, Toolbar, Typography } from '@mui/material';
import { Filters } from '../Filters';
import { sx } from './styles';
import { IStream } from '../../services/api/types';
import { Settings } from '../Settings/Settings';
import { CrmApiClientFactory } from '../../services/crm/client';
import { useState } from 'react';

interface Props {
  availableStreams: IStream[];
  openChat: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoWhite = require('./../../assets/images/logo_white.png');

export function Header ({ availableStreams, openChat }: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const isLive = pathname === '/live';
  const isClips = pathname === '/clips';
  const crmApiClient = CrmApiClientFactory(navigate);
  
  crmApiClient.getProjectName().then((rawResponse) => {
    const response = rawResponse ? rawResponse[0] : {};

    setTitle(response?.title);
  });

  return (
    <AppBar position="fixed" id={'app-bar'} sx={sx.root}>
      <Toolbar>
        <Stack direction="row" sx={sx.stack}>
          <Button
            sx={sx.label(isLive)}
            onClick={() => navigate('/live')}
          >
            Live
          </Button>
          <Button
            sx={sx.label(isClips)}
            onClick={() => navigate('/clips')}
          >
            Clips
          </Button>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={sx.logoWrapper}
        >
          <Typography>{title}</Typography>
          <img src={logoWhite} style={sx.logo} />
        </Box>
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
          <Filters streams={availableStreams} />
          <Settings openChat={openChat} />
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
