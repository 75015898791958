import React, { useState } from 'react';
import { Button, Card, Rating, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IComment } from '../../services/crm/types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { CrmApiClientFactory } from '../../services/crm/client';
import StarIcon from '@mui/icons-material/Star';
import { itsonMuiTheme } from '../../theme/ITSONMUITheme';

type Props = {
  comment: IComment;
  updateComment: (id: string, content: string, rate: number) => void;
}

export const Comment = (props: Props) => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [rate, setRate] = useState(0);
  const [isSaving, setSaving] = useState(false);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState<IComment|null>(null)

  const crmApiClient = CrmApiClientFactory(navigate);

  const editComment = () => {
    setIsEditModeEnabled({...props.comment})
    setContent(props.comment.content)
    setRate(props.comment.rate)
  }

  const saveComment = () => {
    setSaving(true);
    crmApiClient.editComment(props.comment.id, rate, content)
      .then((response: any) => {
        setSaving(false);

        //toast.success('Comment edited', toastConfig);
        props.updateComment(props.comment.id, content, rate)
        cancel()
      })
      .catch((error: any) => {
        console.log(error);
        setSaving(false);
        //toast.error('Editing comment failed', toastConfig);
      })
    ;
  }

  const cancel = () => {
    setIsEditModeEnabled(null)
  }

  return (
    <Card>
      <Box sx={{ p: '15px', backgroundColor: itsonMuiTheme.palette.background.default }}>
        <Stack spacing={0} direction="row" sx={{ width: '100%' }}>
          <Box sx={{ width: '100%', background: itsonMuiTheme.palette.background.default }}>
            <Stack
              sx={{width: '100%'}}
              spacing={0}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack sx={{ width: '100%' }} spacing={0} direction="row" alignItems="center" justifyContent={"space-between"}>
                <Rating
                  readOnly={!isEditModeEnabled}
                  name="simple-controlled"
                  icon={<StarIcon sx={{color: '#91a6ff'}} />}
                  value={!isEditModeEnabled ? props.comment.rate : rate}
                  onChange={(event, newValue) => {
                    setRate(newValue || 0);
                  }}
                />
                <Stack direction={'row'} alignItems={'center'}>
                  { props.comment.editable ? (
                  <IconButton onClick={editComment}>
                    <EditIcon />
                  </IconButton>
                  ) : '' }
                  <Typography
                    fontWeight="bold"
                    sx={{ color: 'neutral.darkBlue' }}
                  >
                    {props.comment.author.firstName} {props.comment.author.lastName}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            { !isEditModeEnabled && props.comment.content && props.comment.content.length ? (
            <Typography sx={{ color: "neutral.grayishBlue", p: "20px 0" }}>
              {props.comment.content}
            </Typography>
            ) : '' }
            { isEditModeEnabled ? (
              <TextField
                multiline
                fullWidth
                minRows={4}
                id="outlined-multilined"
                placeholder="Add a comment"
                value={!isEditModeEnabled ? props.comment.content : content}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
              />
            ) : '' }
            { isEditModeEnabled ? (
              <Box sx={{ marginTop:2 }}>
                <Button
                  disabled={(!rate && !content) || isSaving}
                  size="large"
                  sx={{
                    bgcolor: "custom.moderateBlue",
                    color: "neutral.white",
                    p: "8px 25px",
                    "&:hover": {
                      bgcolor: "custom.lightGrayishBlue",
                    },
                  }}
                  onClick={saveComment}
                >
                  Save
                </Button>
                <Button
                  size="large"
                  sx={{
                    bgcolor: "custom.moderateBlue",
                    color: "neutral.white",
                    p: "8px 25px",
                    "&:hover": {
                      bgcolor: "custom.lightGrayishBlue",
                    },
                  }}
                  onClick={cancel}
                >Cancel</Button>
              </Box>
            ): '' }
          </Box>
        </Stack>
      </Box>
    </Card>
  )
}

