import React from 'react';
import { AppBar, Dialog, DialogActions, DialogContent, ImageListItem, ImageListItemBar, Tooltip } from '@mui/material';
import { IPhoto } from '../../services/api/types';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { useCopyToClipboard } from 'usehooks-ts';
import { Comments } from '../Comments';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

type Props = {
  photo: IPhoto;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 0,
  pt: 2,
  px: 4,
  pb: 3,
};

export function Photo (props: Props) {
  const { photo } = props;
  const [openComment, setOpenComment] = React.useState(false);
  const [openPhoto, setOpenPhoto] = React.useState(false);
  const [value, copy] = useCopyToClipboard()

  const formatDate = (dateStr: string): string => {
    const d = new Date(dateStr);
    return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
  };

  const handleOpenComment = () => {
    setOpenComment(true);
  };

  const handleCloseComment = () => {
    setOpenComment(false);
  };

  const handleOpenPhoto = () => {
    setOpenPhoto(true);
  };

  const handleClosePhoto = () => {
    setOpenPhoto(false);
  };

  return (
    <ImageListItem key={photo.id}>
      <img
        onClick={handleOpenPhoto}
        src={`${photo.url}`}
        alt={photo.name}
        loading="lazy"
      />
      <ImageListItemBar
        title={photo.name}
        actionIcon={
          <Tooltip title="Commend and rate">
            <IconButton size="small" sx={{marginRight: 1}}
                        aria-label="rate"
                        onClick={handleOpenComment}>
              <StarIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={openComment}
        onClose={handleCloseComment}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseComment}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Comments
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{padding: 0}}>
          <Comments close={handleCloseComment} type={'photo'} id={photo.id} />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={openPhoto}
        onClose={handleClosePhoto}
      >
        <DialogContent sx={{padding: 0}}>
          <img
            src={`${photo.url}`}
            alt={photo.name}
            style={{width: 'auto',maxWidth: '100%'}}
            loading="lazy"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePhoto}>Close</Button>
        </DialogActions>
      </Dialog>
    </ImageListItem>
  )
}
