import { Message } from '../Message';
import { Stack } from '@mui/material';
import React from 'react';
import { IMessage } from '../../../services/crm/types';

type Props = {
  messages: {[id: string]: IMessage};
}

export const Conversation = ({ messages }: Props) => {
  return (
    <Stack direction={'column'} spacing={3} sx={{ paddingTop: 2, paddingLeft: '8px', paddingBottom: 2}}>
      { Object.values(messages).map(message => <Message key={message.id} message={message} />)}
      <div id={"conversation-bottom"}></div>
    </Stack>
  )
}
