import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import './style.scss';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Chat, Close, People } from '@mui/icons-material';
import { IGroupWithMessage, IMessage, IUser } from '../../../services/crm/types';
import { Conversation } from '../Conversation';
import { useNavigate } from 'react-router-dom';
import { CrmApiClientFactory } from '../../../services/crm/client';
import { ActiveUser } from '../ActiveUsers';
import { Group } from '../Group';
import { toast } from 'react-toastify';
import Input from '../../Input/Input';
import { ChatMessage, ChatService } from '../../../services/chat/chat';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

type Props = {
  visible: boolean;
  user: IUser;
  guest: boolean;
}

const styles = {
  position: 'absolute',
  bottom: '70px',
  margin: '20px',
  right: '0',
  width: {
    xs: '90%',
    md: '500px',
  },
}

// let loadingLastMessages = false;

export const ChatPopup = ({ visible, user, guest }: Props) => {
  const navigate = useNavigate();
  const crmApiClient = CrmApiClientFactory(navigate);

  const { savedValue } = useLocalStorage<string>('session');
  const [sending, setSending] = useState(false);
  const [usersVisible, showUsers] = useState(false);
  const [users, setUsers] = useState([] as IUser[]);
  const [title, setTitle] = useState('');
  const [currentGroup, setCurrentGroup] = useState({} as IGroupWithMessage);
  const [messages, setMessages] = useState({} as ChatMessage);
  const [groups, setGroups] = useState([] as IGroupWithMessage[]);
  const appBar = document.getElementById('app-bar');
  const chatMessage = {
    message: '',
    ref: {} as any,
  };
  let appBarHeight = 64;
  if (appBar !== null) {
    appBarHeight = appBar.clientHeight;
  }
  const innerHeight = window.innerHeight;
  const contentHeight = innerHeight * 0.60; //- appBarHeight - 90 - 200;

  const chatService = ChatService.getInstance(crmApiClient);
  useEffect(() => {
    chatService.messageChannel.subscribe(({ messages, groups }) => {
      setGroups(groups);
      let lastOpenedGroup = localStorage.getItem('chat-group') || 0;
      if (lastOpenedGroup === 'undefined') {
        lastOpenedGroup = -1;
      }
      if (+lastOpenedGroup === -1) {
        closeGroup();
      } else {
        let groupToOpen: IGroupWithMessage = {} as IGroupWithMessage;
        let generalGroup: IGroupWithMessage = {} as IGroupWithMessage;
        if (!currentGroup || !currentGroup.id) {
          for (const group of groups) {
            if (group.isGeneral) {
              generalGroup = group;
            }
            if (lastOpenedGroup && group.id === +lastOpenedGroup) {
              groupToOpen = group;
              break;
            }
          }
          if (groupToOpen) {
            openGroup(groupToOpen);
          } else if (generalGroup) {
            openGroup(generalGroup);
          }
        }
      }

      setMessages(messages);
    });
  }, []);

  useEffect(() => {
    if (savedValue) {
      crmApiClient.getUsers(0)
        .then((users: IUser[]) => {
          setUsers(users);
        });

      chatService.load()
        .then(() => {
          scrollToBottom();
        });
    }
  }, []);

  const switchView = () => {
    if (!usersVisible) {
      setTitle("Active users");
    } else {
      if (currentGroup && currentGroup.id > 0) {
        // if (currentGroup.isGeneral) {
        //   setTitle("General chat");
        // } else if (currentGroup.isTechnician) {
        //   setTitle("Technician chat")
        // } else {
        // }
        setTitle(currentGroup.title);
      } else {
        setTitle('Chats');
      }
    }
    showUsers(!usersVisible);
  }

  const closeGroup = () => {
    setCurrentGroup({} as IGroupWithMessage);
    setTitle("All chats");
    localStorage.setItem('chat-group', '-1');
  }

  const openGroup = (group: IGroupWithMessage) => {
    if (!group || !group.id) {
      return;
    }
    group.unread = false;
    localStorage.setItem('chat-group', ''+group.id);
    setCurrentGroup(group);
    crmApiClient.readMessage(group.id).then();
    chatService.messageReadChannel.next(groups);
    if (group && group.id > 0) {
      // if (group.isGeneral) {
      //   setTitle("General chat");
      // } else if (group.isTechnician) {
      //   setTitle("Technician chat")
      // } else {
      // }
      setTitle(group.title);
    }
    scrollToBottom();
  }

  const createGroup = (user: IUser) => {
    crmApiClient.createChatGroup(user.id)
      .then((chatGroup: IGroupWithMessage) => {
        showUsers(false)
        openGroup(chatGroup);
        chatService.load().then();
      });
  }

  const onMessageChange = (event: any) => {
    chatMessage.message = event.target.value;
    chatMessage.ref = event.target;
  }

  const onKeyDown = (event: any) => {
    if (event.code === 'Enter') {
      send();
    }
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      const bottom = document.getElementById('conversation-bottom');
      if (bottom !== null) {
        bottom.scrollIntoView(false);
      }
    }, 10);
  }

  const send = () => {
    if (!chatMessage.message || chatMessage.message.length < 3) {
      return;
    }

    setSending(true);
    crmApiClient.createMessage(currentGroup.id, chatMessage.message)
      .then((createdMessage: IMessage) => { // IGroupWithMessage[]) => {
        messages[createdMessage.group.id] = messages[createdMessage.group.id] || {};
        messages[createdMessage.group.id][createdMessage.id] = createdMessage;
        setMessages(messages);
        setSending(false);
        chatMessage.ref.value = '';
        // chatMessage.ref.style.height = '50px';
        scrollToBottom();
        chatService.load().then();
      })
      .catch(() => {
        setSending(false);
        toast.error('Could not send message');
      })
  }

  return (
    <Box className={'chat-popup'}>
      { visible ? (
        <Card sx={styles}>
          <CardHeader
            sx={{ fontSize: '1rem', backgroundColor: grey['900'] }}
            avatar={
              <Avatar src={user.avatar} aria-label="recipe"></Avatar>
            }
            action={
              <Stack direction={'row'} spacing={0}>
                { !usersVisible && (currentGroup && currentGroup.id) ? (
                  <IconButton onClick={closeGroup}>
                    <Close />
                  </IconButton>
                ) : '' }
                { !guest ? (
                  <IconButton aria-label="people" onClick={switchView}>
                    {usersVisible ? <Chat /> : <People />}
                  </IconButton>
                ) : '' }
              </Stack>

            }
            title={title}
          />
          <CardContent id={'chat-content'} sx={{ overflowY: 'scroll', height: `${contentHeight}px`, padding: 0 }}>
            { usersVisible ? (
              <Stack direction={'column'} spacing={3} sx={{ paddingTop: 2, paddingBottom: 2}}>
                { users.map(user => <Box key={user.id} onClick={() => createGroup(user)}><ActiveUser user={user} /></Box>)}
              </Stack>
            ) : (currentGroup && currentGroup.id > 0 ? (
              <Conversation messages={messages[currentGroup.id] || []} />
            ) : (
              <Stack direction={"column"} spacing={1}>
                { groups.map(group => <Box key={group.id} onClick={() => openGroup(group)}><Group group={group} /></Box>) }
              </Stack>
            )) }
          </CardContent>
          <CardActions sx={{ backgroundColor: grey['900'] }}>
            {/*<TextArea onChange={onMessageChange} onKeyPress={onKeyPress} />*/}
            {currentGroup && currentGroup.id > 0 ? (
              <Input onChange={onMessageChange} onKeyDown={onKeyDown} disabled={sending} />
            ): <div style={{height: '42px'}}></div> }
            { currentGroup && currentGroup.id > 0 ? (
              <IconButton type="button" aria-label="send" onClick={send} disabled={sending}>
                <SendIcon />
              </IconButton>
            ) : '' }
          </CardActions>
        </Card>
      ) : ''}
    </Box>
  )
}
