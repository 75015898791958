import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { CrmApiClientFactory } from '../../services/crm/client';
import { IGuestSession, ISession, IUser } from '../../services/crm/types';
import React from 'react';
import { toast } from 'react-toastify';
import { Grid, Stack } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { ChatPopup } from '../../components/Chat/ChatPopup/ChatPopup';
import Launcher from '../../components/Chat/Launcher/Launcher';
import videojs from 'video.js';
import { ChatService } from '../../services/chat/chat';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const background = require('./../../assets/images/background.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const backgroundMobile = require('./../../assets/images/background_mobile.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('./../../assets/images/logo.png');

let guestChatWatcher: any = null;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    color: '#000000',
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '0',
    borderColor: '#FFFFFF',
    fontSize: 16,
    width: '100%  ',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: '#FFFFFF',
    },
  },
}));

export function Login () {
  const navigate = useNavigate();
  const [ session, setSession ] = React.useState<ISession | null>(null);
  const crmApiClient = CrmApiClientFactory(navigate);
  const { savedValue, saveValue } = useLocalStorage<string>('session');
  const userStorage = useLocalStorage<IUser>('user');
  const [ isLogging, setIsLogging ] = React.useState(false)
  const [ formVisible, setFormVisible ] = React.useState(false)
  const [chatVisible, setChatVisibility] = React.useState(false);
  const [chatRendered, setChatRendered] = React.useState(true);
  const [user, setUser] = React.useState({} as IUser);

  const onFormToggle = () => {
    setFormVisible(!formVisible);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLogging(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    if (!email || !password) {
      toast.warning('Enter email and password');
      setIsLogging(false);
      return;
    }
    crmApiClient.login(email, password)
      .then((sess: ISession) => {
        if (guestChatWatcher) {
          clearInterval(guestChatWatcher);
        }
        saveValue(sess.accessToken);
        setSession(sess);
        crmApiClient.getIdentity().then((user: IUser) => {
          userStorage.saveValue(user);
        });
        navigate('/');
      })
      .catch((error: any) => {
        console.log(error);
        toast.error('Invalid e-mail or password');
        setIsLogging(false);
      })
  };

  const onChatToggle = () => {
    if (!savedValue) {
      let guestId = localStorage.getItem('g.id');
      if (!guestId) {
        guestId = (Math.random() + 1).toString(36).substring(7);
        localStorage.setItem('g.id', guestId);
      }
      crmApiClient.createGuestSession(guestId)
        .then((guestSession: IGuestSession) => {
          saveValue(guestSession.accessToken);
          setSession(guestSession);
          crmApiClient.getIdentity().then((usr: IUser) => {
            userStorage.saveValue(usr);
            const chatService = ChatService.getInstance(crmApiClient);
            chatService.createSession()
              .then(() => {
                setUser(usr);
                chatService.watch().then();
                guestChatWatcher = setInterval(() => {
                  chatService.watch().then();
                }, 21 * 1000);
              });
          });
        })
        .catch((error: any) => {
          console.log('guest session error', error);
        })
    } else {
      setUser(userStorage.savedValue);
    }
    setChatVisibility(!chatVisible);
    setTimeout(() => {
      const bottom = document.getElementById('conversation-bottom');
      if (bottom !== null) {
        bottom.scrollIntoView(false);
      }
    }, 100);

  };

  return (
    <Container component="main" sx={{
      maxWidth: '100% !important',
      backgroundImage: {  md: `url(${background})`, xs: `url(${backgroundMobile})` },
      backgroundSize: 'cover',
    }}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: { xs: `${window.innerHeight}px`, md: "100vh" } }}
      >
        <Box sx={{ position: 'absolute', top: 0}}>
          <img style={{width: '200px'}} src={logo} />
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate
          sx={{
            background: 'linear-gradient(42deg, rgba(112,93,208,1) 0%, rgba(71,52,142,1) 60%, rgba(47,31,93,1) 100%)',
            padding: { md: '30px', xs: '15px' },
            borderRadius: '10px',
          }}
        >
          <Typography sx={{fontSize: '1.1rem', color: '#91a6ff', paddingBottom: 2, textTransform: 'uppercase'}}>Username</Typography>
          <BootstrapInput
            required
            fullWidth
            id="email"
            name="email"
            autoFocus
            sx={{paddingBottom: 2}}
          />
          <Typography sx={{fontSize: '1.1rem', color: '#91a6ff', paddingBottom: 2, textTransform: 'uppercase'}}>Password</Typography>
          <BootstrapInput
            required
            fullWidth
            name="password"
            type="password"
            id="password"
          />
          <Button
            type="submit"
            variant="text"
            disabled={isLogging}
            sx={{ mt: 3, mb: 0, fontSize: '1.2rem', color: '#91a6ff', float: 'right', backgroundColor: 'transparent' }}
          >
            LOG IN
          </Button>
        </Box>
        <Box sx={{ color: '#000000', position: 'absolute', bottom: '10px', left: '10px'}}>
          <Typography>&copy;itson2023</Typography>
        </Box>
      </Stack>
      { chatRendered && user && user.id ? (
        <ChatPopup visible={chatVisible} user={user} guest={true} />
      ) : '' }
      { chatRendered ? (
        <Launcher showBadge={true} toggle={onChatToggle} />
      ) : '' }
        {/*<ContactForm visible={formVisible} />*/}
        {/*<ContactFormLauncher showBadge={false} toggle={onFormToggle} />*/}
    </Container>
  );
}
