import cn from 'classnames';

import Badge from './Badge/Badge';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';
import { useEffect, useState } from 'react';
import { ChatService } from '../../../services/chat/chat';
import { useNavigate } from 'react-router-dom';
import { CrmApiClientFactory } from '../../../services/crm/client';
import { IGroupWithMessage } from '../../../services/crm/types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const chatIcon = require('../../../assets/images/chat_icon.png') as string;

type Props = {
  toggle: () => void;
  showBadge?: boolean;
}

function Launcher ({ toggle, showBadge }: Props) {
  const navigate = useNavigate();
  const crmApiClient = CrmApiClientFactory(navigate);

  const [ isChatShown, showChat ] = useState(false);
  const [ badgeCount, setBadgeCount ] = useState(0);
  const chatService = ChatService.getInstance(crmApiClient);

  const countMessages = (groups: IGroupWithMessage[]) => {
    let unreadGroups = 0;
    for (const group of groups) {
      if (group.unread) {
        unreadGroups++;
      }
    }
    setBadgeCount(unreadGroups);
  }

  useEffect(() => {
    chatService.messageReadChannel.subscribe((groups) => {
      countMessages(groups);
    });
    chatService.messageChannel.subscribe(({ messages, groups }) => {
      countMessages(groups);
    });
  }, []);

  const toggleChat = () => {
    toggle();
    if (!showChat) setBadgeCount(0);
    showChat(!isChatShown);
  }

  return (
    <button
      type="button"
      className={cn('rcw-launcher', { 'rcw-hide-sm': showChat })}
      onClick={toggleChat}
      aria-controls="chat"
    >
      {showBadge && <Badge badge={badgeCount} />}
      {!isChatShown
        ? <img src={chatIcon} width="40" height="40" />
        : <CloseIcon />
      }
    </button>
  );
}

export default Launcher;
