import { Alert, Button, Card, DialogActions, Rating, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import { useNavigate } from 'react-router-dom';
import { IComment } from '../../services/crm/types';
import { CrmApiClientFactory } from '../../services/crm/client';
import 'react-toastify/dist/ReactToastify.css';
import StarIcon from '@mui/icons-material/Star';
import { itsonMuiTheme } from '../../theme/ITSONMUITheme';

type Props = {
  id: string;
  type: string;
  onCommentCreated: () => void;
  close: () => void;
}

export const AddComment = (props: Props) => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [rate, setRate] = useState(0);
  const [isSaving, setSaving] = useState(false);
  const [result, setResult] = useState(0);
  const [existingComment, setExistingComment] = useState<IComment|null>(null);

  const crmApiClient = CrmApiClientFactory(navigate);

  const getComment = () => {
    crmApiClient.getComment(props.type, props.id)
      .then((comment: IComment) => {
        setExistingComment(comment);
      })
      .catch((error: any) => {
        console.log(error);
        setExistingComment(null);
      });
  }

  useEffect(() => {
    getComment();
  }, []);

  const addComment = () => {
    setSaving(true);
    crmApiClient.createComment(props.type, props.id, rate, content)
      .then((response: any) => {
        setSaving(false);
        getComment();
        props.onCommentCreated();
        setContent("")
        setRate(0)
      })
      .catch((error: any) => {
        console.log(error);
        setSaving(false);
      })
    ;
  }

  return (
    <Card sx={{ marginTop: 0, backgroundColor: itsonMuiTheme.palette.background.default }}>
      {result !== 0 ? (
        <Box sx={{marginBottom: 2}}>
          {result === 1 ? (
            <Alert severity={'success'}>Comment created</Alert>
          ) : (
            <Alert severity={'error'}>Could not create comment</Alert>
          )}

        </Box>
      ): '' }
      {!existingComment ? (
      <Box sx={{ p: '15px', backgroundColor: itsonMuiTheme.palette.background.default }}>
        <Stack sx={{backgroundColor: itsonMuiTheme.palette.background.default }} direction="column" spacing={2} alignItems="flex-start">
          <Box
            sx={{
              backgroundColor: itsonMuiTheme.palette.background.default,
              width: 200,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box sx={{ mr: 2 }}>Rate</Box>
            <Rating
              name="simple-controlled"
              value={rate}
              precision={1}
              icon={<StarIcon sx={{color: '#91a6ff'}} />}
              onChange={(event, newValue) => {
                setRate(newValue || 0);
              }}
            />
          </Box>
          <TextField
            multiline
            fullWidth
            minRows={4}
            id="outlined-multilined"
            placeholder="Add a comment"
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <DialogActions>
            <Button
              disabled={(!rate && !content) || isSaving}
              size="large"
              sx={{
                bgcolor: "custom.moderateBlue",
                color: "neutral.white",
                p: "8px 25px",
                "&:hover": {
                  bgcolor: "custom.lightGrayishBlue",
                },
              }}
              onClick={addComment}
            >
              Send
            </Button>
            <Button
              size="large"
              sx={{
                bgcolor: "custom.moderateBlue",
                color: "neutral.white",
                p: "8px 25px",
                "&:hover": {
                  bgcolor: "custom.lightGrayishBlue",
                },
              }}
              onClick={props.close}
            >Close</Button>
          </DialogActions>
        </Stack>
      </Box>
      ): (
        // <Box sx={{ p: "15px" }}>
        //   <Typography>You've already rated this clip on { existingComment.createdAt }</Typography>
        // </Box>
        ''
      )}
    </Card>
  );
};

export default AddComment;
