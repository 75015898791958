import * as React from 'react';
import Input from '@mui/base/Input';
import { styled } from '@mui/system';
import { color } from '../../theme/color';

const CustomInput = React.forwardRef(function CustomInput (
  props: React.InputHTMLAttributes<HTMLInputElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return <Input slots={{ input: StyledInputElement }} {...props} ref={ref} />;
});

type Props = {
  onChange: any;
  onKeyDown: any;
  disabled: boolean;
}

export default function UnstyledInputIntroduction ({ onChange, onKeyDown, disabled }: Props) {
  return <CustomInput
    onChange={onChange}
    onKeyDown={onKeyDown}
    disabled={disabled}
    aria-label="Message input" placeholder="Type something…" />;
}

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledInputElement = styled('input')(
  ({ theme }) => `
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: #ffffff;
  background: ${color.base.black.opacity5};
  border: 1px solid ${grey[700]};
  box-shadow: 0px 0px 0px ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};

  &:hover {
    border-color: ${grey[400]};
  }

  &:focus {
    border-color: ${grey[400]};
    box-shadow: 0 0 0 1px ${theme.palette.mode === 'dark' ? grey[600] : grey[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`);
