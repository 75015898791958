import { Alert, Box, CircularProgress, Grid, ImageList, Paper } from '@mui/material';
import React, { Component, useEffect, useState } from 'react';
import { NavigateFunction, Location, useLocation, useNavigate } from 'react-router-dom';
import { CrmApiClient, CrmApiClientFactory } from '../../services/crm/client';
import { IPhoto, IRecording } from '../../services/api/types';
import { Photo } from '../../components/Photo';
import { useWidth } from '../../hooks/useWidth';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { yellow } from '@mui/material/colors';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Paginator } from '../../components/Paginator';
import { itsonMuiTheme } from '../../theme/ITSONMUITheme';

type State = {
  photos: IPhoto[];
  loadingPhotosError: boolean;
  isLoading: boolean;
  isExpanded: boolean;
  currentPage: number;
}

type Props = {
  navigate: NavigateFunction;
  location: Location;
  breakpoint: string;
  isExpanded: boolean;
}

class PhotosComponent extends Component<Props, State> {

  crmApiClient: CrmApiClient;
  pageSize = 12;

  constructor (props: Props) {
    super(props);

    const searchParams = new URLSearchParams(location.search);

    this.state = {
      photos: [],
      loadingPhotosError: false,
      isExpanded: this.props.isExpanded,
      isLoading: false,
      currentPage: +(searchParams.get('ppage') || 1),
    };
    this.crmApiClient = CrmApiClientFactory(this.props.navigate);
  }

  componentDidMount () {
    this.setState({
      ...this.state,
      isLoading: true,
    })
    this.crmApiClient.getPhotos()
      .then((photos: IPhoto[]) => {
        this.setState({
          ...this.state,
          photos,
          loadingPhotosError: false,
          isLoading: false,
        });
      })
      .catch((error: any) => {
        this.setState({
          ...this.state,
          photos: [],
          loadingPhotosError: true,
          currentPage: 1,
          isLoading: false,
        });
      });

    const bodyEl = document.getElementsByTagName('body');
    if (bodyEl !== null) {
      bodyEl[0].classList.remove('overflow-hidden');
    }
  }

  paginate (photos: IPhoto[], page: number): IPhoto[] {
    if (!photos) {
      return [];
    }
    return [...photos].slice((page - 1) * this.pageSize, page * this.pageSize);
  }

  onPageChange (page: number) {
    this.setState({
      ...this.state,
      currentPage: page,
    });
    this.storePage(page);
  }

  storePage (page: number) {
    const url = new URL(window.location.href);
    url.searchParams.set("ppage", `${page}`);
    window.history.pushState(null, '', url.toString());
  }

  render () {
    let cols = 2;
    switch (this.props.breakpoint) {
      case 'xl':
        cols = 6;
        break;
      case 'lg':
      case 'md':
        cols = 4;
        break;
      case 'sm':
      case 'xs':
        cols = 2;
        break;
    }
    let content;
    if (this.state.isLoading) {
      content = (
        <Box sx={{ display: 'flex', paddingTop: 1, paddingBottom: 2 }}><CircularProgress sx={{margin: '0 auto', marginTop: 1}} /></Box>
      )
    } else if (this.state.loadingPhotosError) {
      content = (
        <Grid sx={{ paddingTop: 2 }} item xs={12}>
          <Alert variant="outlined" severity="error">
            Could not load photos
          </Alert>
        </Grid>
      );
    } else if (!this.state.photos || this.state.photos.length === 0) {
      content = (
        <Grid sx={{ padding: 2 }} item xs={12}>
          <Typography color={itsonMuiTheme.palette.primary.main}>
            No photos found
          </Typography>
        </Grid>
      );
    } else {
      content = (
        <Box sx={{padding: 0, paddingBottom: 1}}>
          <ImageList sx={{ width: '100%', height: '100%' }}
                     cols={cols}>
            {this.paginate(this.state.photos, this.state.currentPage).map((photo: IPhoto) =>
              <Photo
                key={photo.id}
                photo={photo} />
            )}
          </ImageList>
          <Paginator
            count={this.state.photos.length}
            pageSize={this.pageSize}
            currentPage={this.state.currentPage}
            onPageChange={this.onPageChange.bind(this)} />
        </Box>
      )
    }
    return (
      <Box sx={{ paddingLeft: '5px', paddingRight: '5px' }}>
        { content }
      </Box>
    )
  }
}

export const Photos = (props: any) => {
  const breakpoint = useWidth()
  const navigate = useNavigate()
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('page') === 'photos') {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [location.search])
  return (<PhotosComponent {...props} isExpanded={isExpanded} breakpoint={breakpoint} navigate={navigate}/>)
}
