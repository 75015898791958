import type { PaletteOptions, Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import type { ITSONTheme } from './ITSONTheme';
import { itsonTheme } from './ITSONTheme';

interface ITSONMuiThemeOptions extends ThemeOptions {
  itson?: ITSONTheme;
}

const DEFAULT_FONT_FAMILY = '"Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

const palette: PaletteOptions = {
  primary: {
    main: '#91a6ff',
  },
  contrastThreshold: 4.5, // minimum contrast, for accessibility https://mui.com/material-ui/customization/palette/#accessibility
  mode: 'dark',
  background: {
    default: '#1a1a1a',
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
};

const components: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        fontFamily: DEFAULT_FONT_FAMILY,
      },
      ':root': {
        fontSize: 16,
      },
      body: {
        backgroundColor: '#1a1a1a',
      },
      a: {
        color: '#91a6ff',
        textDecoration: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
      },
      root: {
        fontSize: 14,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      fontFamily: DEFAULT_FONT_FAMILY,
    },
  },
};

const themeOptions: ITSONMuiThemeOptions = {
  palette,
  typography: {
    fontFamily: DEFAULT_FONT_FAMILY,
  },
  components,
  itson: itsonTheme,
};

export interface ITSONMuiTheme extends Theme {
  itson: ITSONTheme;
}

export const itsonMuiTheme = createTheme(themeOptions) as ITSONMuiTheme;
