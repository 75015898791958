const parseUTC = (s: string): Date => {
  const b: any[] = s.split(/\D/);
  return new Date(Date.UTC(b[0],--b[1],b[2],b[3],b[4],b[5]))
}

const convertDate = (date: any) => {
  let dd: Date;
  try {
    const d = new Date(`${date} UTC`);
    dd = new Date(d);
    if (dd.toString().indexOf('Invalid') > -1) {
      throw new Error('invalid date');
    }
  } catch (e) {
    dd = parseUTC(date);
  }

  // console.log(date, '->', dd.toISOString());
  return dd.toISOString();
}

export { convertDate }
