import { CssBaseline } from '@mui/material';
import { WrappedThemeProvider } from './theme/WrappedThemeProvider'
import { AppRoutes } from './routes/Routes';
import './assets/css/splash.css';
import './assets/css/main.css';
import { useEffect, useState } from 'react';
import './components/Intercom/plugin';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const icon = require('./assets/images/icon.png');

export default function App () {
  const [loaderVisible, setLoaderVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoaderVisible(false);
    }, 1600);
  })
  return (
    <WrappedThemeProvider>
      { loaderVisible ? (
      <div className="loader-wrapper" id={"loader"}>
        <div className="loader">
          <img src={icon} />
        </div>
      </div>
      ) : '' }
      <CssBaseline />
      <AppRoutes />
    </WrappedThemeProvider>
  );
}
