import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button, ClickAwayListener,
  Divider,
  IconButton, Menu, MenuItem,
  MenuList, Stack,
} from '@mui/material';
import { sx } from './styles';

interface Props {
  openChat: () => void;
}

export function Settings ({ openChat }: Props) {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogout = () => {
    handleClose();
    localStorage.removeItem('user');
    localStorage.removeItem('session');
    navigate('/login');
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(!openMenu);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleContactUs = () => {
    openChat();
    handleClose();
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Stack>
        <IconButton 
          onClick={handleOpen}
        >
          <SettingsIcon />
        </IconButton>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={sx.menu}
          disableAutoFocusItem
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        > 
          <MenuList dense>
            <MenuItem sx={sx.menuTitle}>
              SETTINGS
            </MenuItem>
            <Divider/>
            <MenuItem>
              <Button onClick={handleLogout} sx={sx.menuItemButton}>Logout</Button>
            </MenuItem>
            <MenuItem>
              <Button onClick={handleContactUs} sx={sx.menuItemButton}>Contact Us</Button>
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </ClickAwayListener>
  )
}
