import { CssBaseline, ThemeProvider } from '@mui/material';
import { itsonMuiTheme } from './ITSONMUITheme';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const WrappedThemeProvider = ({ children }: Props) => {
  return (
    <ThemeProvider theme={itsonMuiTheme}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  );
};
