import { ReceiveMessageCommand, ReceiveMessageCommandOutput, SQSClient } from '@aws-sdk/client-sqs';
import { CrmApiClient } from '../crm/client';
import { IGroupWithMessage, IGroupMessages, IMessage, IUser } from '../crm/types';
import { Subject } from 'rxjs';

interface ISqsSessionData {
  aki: string;
  sak: string;
  st: string;
  mgi: string;
  q: string;
  r: string;
}

export type ChatMessage = { [groupId: number]: {[id: string]: any}};

export class ChatService {

  public messageReadChannel: Subject<any> = new Subject<any>();
  public messageChannel: Subject<any> = new Subject<any>();
  private static instance: ChatService;
  groups: IGroupWithMessage[] = [];
  //messages: Map<number, Map<string, IMessage>> = new Map<number, Map<string, IMessage>>();
  messages: ChatMessage = {} as ChatMessage;
  apiClient: CrmApiClient;
  sqsClient!: SQSClient;
  sqsSessionData!: ISqsSessionData;

  private constructor (apiClient: CrmApiClient) {
    this.apiClient = apiClient;
  }

  public static getInstance (apiClient: CrmApiClient): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService(apiClient);
    }
    return ChatService.instance;
  }

  async createSession () {
    const response = await this.apiClient.createSession();
    const data = JSON.parse(atob(response.sess));
    this.sqsSessionData = data;
    this.sqsClient = new SQSClient({
      region: data.r,
      credentials: {
        sessionToken: data.st,
        secretAccessKey: data.sak,
        accessKeyId: data.aki,
      },
    });
  }

  async load () {
    this.groups = await this.apiClient.getMessages();
    for (const group of this.groups) {
      this.messages[group.id] = this.messages[group.id] || {};
      for (const message of Object.values(group.messages)) {
        this.messages[group.id][message.id] = message;
      }
    }
    this.messageChannel.next({ messages: this.messages, groups: this.groups });
  }

  async watch () {
    try {
      const output = await this.sqsClient.send(new ReceiveMessageCommand({
        // AttributeNames: ['SentTimestamp'],
        AttributeNames: ['CreatedTimestamp'],
        MessageSystemAttributeNames: ['SentTimestamp'],
        MaxNumberOfMessages: 1,
        MessageAttributeNames: ['All'],
        QueueUrl: this.sqsSessionData.q,
        WaitTimeSeconds: 20,
      }));
      if (!output || !output.Messages || !output.Messages.length) {
        return;
      }

      const receiptHandles: string[] = [];
      let groupId = localStorage.getItem('chat-item') || -1;
      if (groupId === 'undefined') {
        groupId = -1;
      }
      // const groupIds: number[] = [];
      for (const message of output.Messages) {
        let body;
        try {
          body = JSON.parse(<string>message.Body);
          if (!groupId) {
            groupId = body.group;
          }
        } catch (e) {
          console.log(e);
        }
        if (message.ReceiptHandle) {
          receiptHandles.push(message.ReceiptHandle);
        }
      }
      await this.load();
      await this.apiClient.readMessages(receiptHandles, [+groupId]);
    } catch (e) {
      console.log('sqs error', e);
      await this.createSession();
    }
  }
}
