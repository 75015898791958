import { itsonMuiTheme } from "../../theme/ITSONMUITheme";

export const sx = {
  cameraTitle: (isActive: boolean) => ({
    color: isActive ? itsonMuiTheme.palette.primary.main : 'white',
    textTransform: 'uppercase',
  }),
  menu: {
    p: 1,
  },
  menuTitle: {
    mt: 1,
  },
}
