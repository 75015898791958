import React from 'react';
import {
  AppBar,
  Button,
  Card,
  CardActions,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  ImageListItem,
  Stack,
  Tooltip,
} from '@mui/material';
import VideoJS from '../VideoJS/VideoJS';
import { IRecording } from '../../services/api/types';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import { useCopyToClipboard } from 'usehooks-ts';
import Box from '@mui/material/Box';
import { Comments } from '../Comments';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { itsonMuiTheme } from '../../theme/ITSONMUITheme';
import { useWidth } from '../../hooks/useWidth';
import { IosShare } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import { IGroup } from '../../services/crm/types';
import { useNavigate } from 'react-router-dom';
import { CrmApiClientFactory } from '../../services/crm/client';
import { ChatService } from '../../services/chat/chat';

type Props = {
  recording: IRecording;
}

const truncate = (s: string, breakpoint: any) => {
  let max = 30;
  switch (breakpoint) {
    case 'xs':
      max = 30;
      break;
    case 'sm':
      max = 60;
      break;
    case 'md':
      max = 90;
      break;
    case 'lg':
    case 'xl':
      max = 120;
      break;
  }
  if (s.length > max) {
    return s.substring(0, max) + "...";
  }
  return s;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 0,
  pt: 2,
  px: 4,
  pb: 3,
};

export function PlaybackVideo (props: Props) {
  const navigate = useNavigate();
  const crmApiClient = CrmApiClientFactory(navigate);
  const breakpoint = useWidth();

  const { recording } = props;
  const [isSharing, setIsSharing] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [shareDialogOpen, setOpenShareDialog] = React.useState(false);
  const [chatGroups, setChatGroups] = React.useState([] as IGroup[]);
  const [value, copy] = useCopyToClipboard();

  const playerRef = React.useRef(null);
  let playerInstance: any = null;

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: true,
    aspectRatio: '16:9',
    width: '100%',
    poster: recording.thumbnail,
    fill: true,
    html5: {},
    sources: [ {
      src: recording.url,
      type: 'video/mp4',
    } ],
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;
    playerInstance = player;

    player.on('waiting', () => {
      console.log('player is waiting');
    });
    player.on('dispose', () => {
      console.log('player will dispose');
    });
    player.on('ant-error', (event: any, errors: any) => {
      console.log('ant-error', errors);
    });
  }

  const formatDate = (dateStr: string): string => {
    const d = new Date(dateStr);
    return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
  };

  const onLinkCopy = () => {
    console.log('copied to clipboard');
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShareDialogOpen = () => {
    setOpenShareDialog(true);
    crmApiClient.getGroups()
      .then((groups: IGroup[]) => {
        setChatGroups(groups);
      });
  };

  const handleShareDialogClose = () => {
    setOpenShareDialog(false);
  };

  const shareInChats = () => {
    setIsSharing(true);
    const promises = [];
    for (const [groupId, flag] of Object.entries(groupsChecked)) {
      if (flag) {
        promises.push(crmApiClient.createMessage(+groupId, recording.url));
      }
    }
    Promise.all(promises).then(() => {
      handleShareDialogClose();
      setIsSharing(false);
      const chatService = ChatService.getInstance(crmApiClient);
      chatService.load().then();
    });
  };

  const groupsChecked: any = {};
  const onChatGroupCheck = (p: any, group: IGroup) => {
    groupsChecked[group.id] = p.target.checked;
  };

  const copyToClipboard = () => {
    copy(recording.url);
    handleShareDialogClose();
  };

  return (
    <ImageListItem>
      <Box sx={{ position: 'relative'}}>
        <Toolbar variant={'dense'} disableGutters={true}
           sx={{
             width: '100%',
             flexGrow: 1,
             paddingLeft: 1,
             paddingRight: 0,
             paddingTop: 0,
             paddingBottom: 0,
             position: 'absolute',
             top: 0,
             zIndex: 1000,
             minHeight: 0,
             left: 0}}>
          <Typography color={recording.stream ? recording.stream.color : '#FFFFFF'} fontSize={'0.8rem'}>
            { recording.name.length > 39 ? recording.name.substring(0, 37) + '...' : recording.name }
          </Typography>
          <Box sx={{ position: 'absolute', right: 0 }}>
            <Tooltip title={"Command and rate"}>
              <IconButton aria-label="rate" sx={{ color: recording.stream ? recording.stream.color : '#FFFFFF', "&:hover": { backgroundColor: "transparent" } }} onClick={handleOpen} size={'small'}>
                <StarIcon sx={{fontSize: '1.1rem'}} />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Copy link"}>
              <IconButton aria-label="link" sx={{ color: recording.stream ? recording.stream.color : '#FFFFFF', "&:hover": { backgroundColor: "transparent" } }} onClick={handleShareDialogOpen} size={'small'}>
                <IosShare sx={{fontSize: '1.1rem'}} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady}/>
      </Box>
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <AppBar position="static" sx={{
          transition: 'none',
          backgroundColor: itsonMuiTheme.palette.background.default,
          boxShadow: 'none',
          backgroundImage: 'none',
          position: 'relative',
        }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color={itsonMuiTheme.palette.primary.main} variant="h6" component="div">
              Comments
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{padding: 0}}>
          <Comments close={handleClose} type={'recording'} id={recording.id} />
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={shareDialogOpen}
        onClose={handleShareDialogClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <AppBar position="static" sx={{
          transition: 'none',
          backgroundColor: itsonMuiTheme.palette.background.default,
          boxShadow: 'none',
          backgroundImage: 'none',
          position: 'relative',
        }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleShareDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} color={itsonMuiTheme.palette.primary.main} variant="h6" component="div">
              Share clip
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{padding: 0}}>
          <Box>
            <Card>
              <CardContent>
                <Box sx={{ p: '15px'}}>
                  <Stack spacing={0} direction="row" sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%'  }}>
                      <Typography>Select chats to share clip with:</Typography>
                      <FormGroup>
                        { chatGroups ? (
                          chatGroups.map(chatGroup => (
                            <FormControlLabel
                              key={chatGroup.id}
                              control={
                                <Checkbox
                                  onChange={(p) => onChatGroupCheck(p, chatGroup)} />}
                                  label={truncate(chatGroup.title, breakpoint)} />
                          ))
                        ) : ( '' )}
                      </FormGroup>
                    </Box>
                  </Stack>
                </Box>
              </CardContent>

              <CardActions sx={{ background: itsonMuiTheme.palette.background.default }}>
                <Box sx={{ marginTop: 1}}>
                  <Button
                    size="large"
                    sx={{
                      bgcolor: "custom.moderateBlue",
                      color: "neutral.white",
                      p: "8px 25px",
                      "&:hover": {
                        bgcolor: "custom.lightGrayishBlue",
                      },
                    }}
                    onClick={shareInChats}
                    disabled={isSharing}
                  >
                    Share
                  </Button>
                  <Button
                    size="large"
                    sx={{
                      bgcolor: "custom.moderateBlue",
                      color: "neutral.white",
                      p: "8px 25px",
                      "&:hover": {
                        bgcolor: "custom.lightGrayishBlue",
                      },
                    }}
                    onClick={copyToClipboard}
                  >
                    Copy link
                  </Button>
                  <Button
                    size="large"
                    sx={{
                      bgcolor: "custom.moderateBlue",
                      color: "neutral.white",
                      p: "8px 25px",
                      "&:hover": {
                        bgcolor: "custom.lightGrayishBlue",
                      },
                    }}
                    onClick={handleShareDialogClose}
                  >Close</Button>
                </Box>
              </CardActions>
            </Card>
          </Box>
        </DialogContent>
      </Dialog>
    </ImageListItem>
  )
}
