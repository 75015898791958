import { CSSProperties } from "react";
import { itsonMuiTheme } from "../../theme/ITSONMUITheme";

export const sx = {
  root: {
    transition: 'none',
    backgroundColor: itsonMuiTheme.palette.background.default,
    boxShadow: 'none',
    backgroundImage: 'none',
    zIndex: 99999,
  },
  label: (isActive: boolean) => ({
    flexGrow: 1,
    color: isActive ? itsonMuiTheme.palette.primary.main : '#fff',
    cursor: isActive ? 'default' : 'pointer',
    fontWeight: isActive ? 700 : 400,
  }),
  logoWrapper: {
    position: 'absolute',
    left: '50%',
    top: '55%',
    transform: 'translate(-50%, -50%)',
    display: { xs: 'none', md: 'block' },
    textAlign: 'center',
  },
  stack: {
    position: 'relative',
  },
  logo: {
    height: '20px', 
    width: 'auto',
  } as CSSProperties,
}
