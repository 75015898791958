import { Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/system';
import { grey } from '@mui/material/colors';
import { IMessage } from '../../../services/crm/types';

import VideoJS from '../../VideoJS/VideoJS';
import React from 'react';
import { useWidth } from '../../../hooks/useWidth';
import { DateTimeAgo } from '../../DateTimeAgo';
import { convertDate } from '../../../helpers/date';

type Props = {
  message: IMessage;
}

const messageStyles = {
  "&:hover": {
    backgroundColor: grey[900],
  },
  paddingLeft: 1,
  paddingRight: 1,
  width: '100%',
};

// const convertDate = (date: any) => {
//   const d = new Date(`${date} UTC`);
//   return new Date(d);
// }

export const Message = ({ message }: Props) => {
  const breakpoint = useWidth();
  const videoJsOptions: any = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: true,
    aspectRatio: '16:9',
    width: '100%',
    // poster: recording.thumbnail,
    fill: true,
    html5: {},
    sources: [ {
      // src: recording.url,
      type: 'video/mp4',
    } ],
  };
  if (message.recording) {
    videoJsOptions.poster = message.recording.thumbnail;
    videoJsOptions.sources[0].src = message.recording.url;
  }
  return (
    <Stack direction="row" spacing={2} sx={messageStyles}>
      <Avatar alt="Remy Sharp" src={message.user.avatar} />
      <Stack direction={"column"} spacing={0} style={{ width: '100%'}}>
        <Box><strong>{ message.user.name } </strong>
          <small title={message.createdAt} style={{ marginLeft: '10px', color: grey[400] }}>
          <DateTimeAgo tooltip={true} date={convertDate(message.createdAt)} /></small></Box>
        <Box>
          {message.recording ? (
            <div><VideoJS options={videoJsOptions} /></div>
          ) : (message.message)}
        </Box>
      </Stack>
    </Stack>
  )
}
