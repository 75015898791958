import { useState } from 'react';

type LocalStorage<T> = {
  savedValue: T;
  saveValue: (newValue: T) => void;
  clear: () => void;
}

export const useLocalStorage = <T>(keyName: string, defaultValue: T = <T>null): LocalStorage<T> => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return <T>JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return <T>defaultValue;
    }
  });

  const saveValue = <T>(newValue: T) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const clear = () => {
    window.localStorage.removeItem(keyName)
  }

  return {
    savedValue: storedValue,
    saveValue,
    clear,
  };
}
