export const color = {
  base: {
    black: {
      main: '#000000',
      opacity90: 'rgba(0, 0, 0, .9)',
      opacity70: 'rgba(0, 0, 0, .7)',
      opacity50: 'rgba(0, 0, 0, .5)',
      opacity20: 'rgba(0, 0, 0, .2)',
      opacity10: 'rgba(0, 0, 0, .1)',
      opacity5: 'rgba(0, 0, 0, .05)',
    },
    blue: {
      50: '#DCF1FF',
      100: '#B4D8FC',
      200: '#89C3F3',
      300: '#5CB0ED',
      400: '#329FE7',
      500: '#198FD2',
      600: '#0B63A1',
      700: '#013F74',
      800: '#002148',
      900: '#000A1E',
    },
    gray: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#DEDEDE',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    white: {
      main: '#ffffff',
      opacity90: 'rgba(255, 255, 255, .9)',
      opacity50: 'rgba(255, 255, 255, .5)',
      opacity20: 'rgba(255, 255, 255, .2)',
    },
  },
  activity: {
    green: {
      50: '#EEFFF5',
      100: '#C9FFE0',
      200: '#A3FFCA',
      300: '#7EFFB4',
      400: '#54F297',
      500: '#3BD07A',
      600: '#27AE60',
      700: '#178C48',
      800: '#0B6A33',
      900: '#034820',
    },
    aqua: {
      50: '#EDFFFD',
      100: '#CDFFFA',
      200: '#ADFFF7',
      300: '#8DFFF4',
      400: '#6DFFF1',
      500: '#4DFFEE',
      600: '#2FE8D6',
      700: '#13BFAF',
      800: '#009688',
      900: '#006D63',
    },
    purple: {
      50: '#F9F5FF',
      100: '#E8D6FF',
      200: '#D7B8FF',
      300: '#C598FE',
      400: '#A97DE0',
      500: '#8E65C3',
      600: '#754FA6',
      700: '#5D3B89',
      800: '#472A6C',
      900: '#321B4F',
    },
    blue: {
      50: '#EAF7FF',
      100: '#BBE6FF',
      200: '#8CD5FF',
      300: '#5EC4FF',
      400: '#2DACF4',
      500: '#198FD2',
      600: '#0A74B0',
      700: '#005B8E',
      800: '#00456C',
      900: '#002F4A',
    },
  },
  tag: {
    gray: {
      50: '#FFFFFF',
      100: '#F0F0F0',
      200: '#DBDBDB',
      300: '#C7C7C7',
      400: '#B3B3B3',
      500: '#9E9E9E',
      600: '#8A8A8A',
      700: '#757575',
      800: '#616161',
      900: '#4D4D4D',
    },
    mustard: {
      50: '#FFFBEE',
      100: '#FFF3D0',
      200: '#FFECB2',
      300: '#FFE494',
      400: '#FFDD76',
      500: '#F7CF55',
      600: '#CFA832',
      700: '#A68217',
      800: '#7D5F04',
      900: '#543F00',
    },
    green: {
      50: '#F4FFF8',
      100: '#DAFFEA',
      200: '#C1FFDC',
      300: '#A8FFCE',
      400: '#8FFFBF',
      500: '#6FE7A3',
      600: '#4EC581',
      700: '#33A363',
      800: '#1E8149',
      900: '#0E5F31',
    },
    brown: {
      50: '#FFF9F1',
      100: '#FFEDD2',
      200: '#FFE1B3',
      300: '#FFD595',
      400: '#FFC876',
      500: '#E5AD59',
      600: '#C38E3D',
      700: '#A17027',
      800: '#7F5515',
      900: '#5D3C08',
    },
  },
  notification: {
    blue: {
      50: '#F4FAFF',
      100: '#D2EBFF',
      200: '#AED8FA',
      300: '#89C3F3',
      400: '#73A9D6',
      500: '#5F90B9',
      600: '#4C779C',
      700: '#3A607E',
      800: '#2A4861',
      900: '#1C3244',
    },
    green: {
      50: '#F6FFEB',
      100: '#E6FFC7',
      200: '#D7FFA3',
      300: '#C7FF80',
      400: '#B7FF5C',
      500: '#A7FF38',
      600: '#8BE21E',
      700: '#6BB908',
      800: '#528F04',
      900: '#3A6700',
    },
    orange: {
      50: '#FFF6E5',
      100: '#FFE6B7',
      200: '#FFD68A',
      300: '#FFC65C',
      400: '#FFB62E',
      500: '#FFA600',
      600: '#D68C00',
      700: '#AD7100',
      800: '#855600',
      900: '#5C3C00',
    },
    red: {
      50: '#FFECEC',
      100: '#FFC3C3',
      200: '#FF9999',
      300: '#FF7070',
      400: '#FE4646',
      500: '#DC2E2E',
      600: '#BA1B1B',
      700: '#980C0C',
      800: '#760202',
      900: '#540000',
    },
  },
};
