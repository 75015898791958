import { IPhoto, IRecording, IStream } from '../api/types';
import { HttpClient, Method } from '../http/client';
import {
  IComment,
  IGroupWithMessage,
  IGroupMessages,
  IMessage,
  ISession,
  ISqsSession,
  IUser,
  IGroup,
  IGuestSession,
} from './types';
import { NavigateFunction } from 'react-router-dom';

class AuthHttpClient extends HttpClient {

  private navigate: NavigateFunction;

  constructor (navigate: NavigateFunction, baseUrl = '', headers: any = {}) {
    super(baseUrl, headers)
    this.navigate = navigate;
  }

  async refreshAccessToken (): Promise<string> {
    const response = await this.request(Method.POST, '/auth/refresh');

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('session', data.accessToken);
      return data.accessToken
    } else {
      return '';
    }
  }

  async makeAuthenticatedRequest (method: Method, url: string, parameters: any = {}) {
    let accessToken = JSON.parse(localStorage.getItem('session') || "");
    this.setHeader('Authorization', `Bearer ${accessToken}`);

    let response = await this.request(method, url, parameters);

    if (response.status === 401) {
      accessToken = await this.refreshAccessToken();
      localStorage.setItem('session', JSON.stringify(accessToken));
      if (!accessToken.length) {
        this.navigate('/login');
        throw new Error(response);
      }
      this.setHeader('Authorization', `Bearer ${accessToken}`);
      response = await this.request(method, url, parameters);
    }
    if (!response.ok) {
      throw new Error(response);
    }
    return response;
  }
}

export class CrmApiClient {

  private authHttpClient: AuthHttpClient;

  constructor (
    private httpClient: HttpClient,
    private navigate: NavigateFunction
  ) {
    this.httpClient.setHeader('Content-Type', 'application/json');
    this.httpClient.setHeader('Accept', 'application/json');
    this.authHttpClient = new AuthHttpClient(this.navigate, this.httpClient.baseUrl, this.httpClient.headers);
  }

  async getIdentity (): Promise<IUser> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/auth/me`)
      .then((response: any) => {
        return response.json();
      });
  }

  async login (email: string, password: string): Promise<ISession> {
    return this.httpClient.request(Method.POST, `/auth/login`, {
      body: JSON.stringify({email, password}),
    })
      .then((response: any) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      });
  }

  async refresh (): Promise<ISession> {
    return this.httpClient.request(Method.POST, `/auth/refresh`)
      .then((response: any) => {
        return response.json();
      });
  }

  async logout (): Promise<void> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.POST, `/auth/logout`)
      .then((response: any) => {
        return response.json();
      });
  }

  async getRecordings (): Promise<IRecording[]> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/recordings`)
      .then((response: any) => {
        return response.json();
      });
  }

  async getPhotos (): Promise<IPhoto[]> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/photos`)
      .then((response: any) => {
        return response.json();
      });
  }

  async getComments (type: string, id: string): Promise<IComment[]> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/comments/${type}/${id}`)
      .then((response: any) => {
        return response.json();
      });
  }

  async getComment (type: string, id: string): Promise<IComment> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/comment/${type}/${id}`)
      .then((response: any) => {
        return response.json();
      });
  }

  async createComment (type: string, id: string, rate: number, content: string): Promise<IComment> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.POST, `/comments`, {
      body: JSON.stringify({
        [type]: id,
        content: content,
        rate: rate,
      }),
    })
      .then((response: any) => {
        return response.json();
      });
  }

  async editComment (id: string, rate: number, content: string): Promise<IComment> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.PATCH, `/comments/${id}`, {
      body: JSON.stringify({
        content: content,
        rate: rate,
      }),
    })
      .then((response: any) => {
        return response.json();
      });
  }

  async deleteComment (id: string): Promise<any> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.DELETE, `/comments/${id}`)
      .then((response: any) => {
        return response.json();
      });
  }

  async getStreams (): Promise<IStream[]> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/streams`)
      .then((response: any) => {
        return response.json();
      });
  }

  async startRecording (stream : string): Promise<any> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.POST, `/recordings`, {
      body: JSON.stringify({ stream }),
    })
      .then((response: any) => {
        return response.json();
      });
  }

  async stopRecording (stream: string): Promise<any> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.PUT, `/recordings`, {
      body: JSON.stringify({ stream }),
    })
      .then((response: any) => {
        return response.json();
      });
  }

  async getStream (streamId: string): Promise<IStream> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/streams/${streamId}`)
      .then((response: any) => {
        return response.json();
      })
  }

  async getMessages (group = 0): Promise<IGroupWithMessage[]> {
    const params: any = {} as any;
    if (group > 0) {
      params.group = group;
    }
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/chat/messages`, params)
      .then((response: any) => {
        return response.json();
      })
      .then((messages: IGroupWithMessage[]) => {
        const general = messages.filter((m: IGroupWithMessage) => m.isGeneral);
        const technician = messages.filter((m: IGroupWithMessage) => m.isTechnician);
        return [
          ...general,
          ...technician,
          ...messages.filter((m: IGroupWithMessage) => !m.isGeneral && ! m.isTechnician),
        ]
      });
  }
  async getProjectName (): Promise<any> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/chat/messages`)
      .then((response: any) => {
        return response.json();
      })
      .then((messages: IGroupWithMessage[]) => {
        return messages;
      });
  }
  async getLastMessages (group = 0, lastMessage = 0): Promise<IMessage[]> {
    const params: any = {} as any;
    if (lastMessage > 0) {
      params.last = lastMessage;
    }
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/chat/messages/${group}/last`, params)
      .then((response: any) => {
        return response.json();
      });
  }

  async getGroupMessages (group: number, page = 1): Promise<IGroupMessages[]> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/chat/messages/${group}`, { page })
      .then((response: any) => {
        return response.json();
      })
  }

  async getGroups (): Promise<IGroup[]> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/chat/groups`)
      .then((response: any) => {
        return response.json();
      })
  }

  async createMessage (groupId: number, message: string): Promise<IMessage> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.POST, `/chat/messages`, {
      body: JSON.stringify({groupId, message}),
    })
      .then((response: any) => {
        return response.json();
      })
  }

  async readMessage (groupId: number): Promise<IMessage> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.PATCH, `/chat/messages/${groupId}`)
      .then((response: any) => {
        return response.json();
      })
  }

  async readMessages (receiptHandles: string[], groups: number[]): Promise<IMessage> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.PATCH, `/chat/messages`, {
      body: JSON.stringify({
        receiptHandles,
        groups,
      }),
    })
      .then((response: any) => {
        return response.json();
      })
  }

  async getUsers (groupId: number): Promise<IUser[]> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.GET, `/chat/users`, { groupId })
      .then((response: any) => {
        return response.json();
      })
  }

  async createChatGroup (userId: string): Promise<IGroupWithMessage> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.POST, `/chat/group`, {
      body: JSON.stringify({ userId }),
    })
      .then((response: any) => {
        return response.json();
      })
  }

  async createSession (): Promise<ISqsSession> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.POST, `/chat/session`)
      .then((response: any) => {
        return response.json();
      })
  }

  async createGuestSession (guestId: string): Promise<IGuestSession> {
    return this.httpClient.request(Method.POST, `/chat/guest`, {
      body: JSON.stringify({id: guestId}),
    })
      .then((response: any) => {
        return response.json();
      })
  }

  async contact (data: any): Promise<ISqsSession> {
    return this.authHttpClient.makeAuthenticatedRequest(Method.POST, `/contact`, {
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        return response.json();
      })
  }
}

export const CrmApiClientFactory = (navigate: NavigateFunction, hasApiPrefix = true): CrmApiClient => {
  const apiUrl = `${process.env.REACT_APP_CRM_API_URL}${hasApiPrefix ? '/api': ''}`;
  const httpClient = new HttpClient(apiUrl);

  return new CrmApiClient(httpClient, navigate);
}
