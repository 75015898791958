import { Box } from "@mui/material";
import './style.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const noStreamVideo = require('../../assets/videos/standByAnimation.mp4');

export function NoStream () {
  return (
    <Box className="no-video-container">
      <video autoPlay muted loop className="no-stream-video">
        <source src={noStreamVideo} type="video/mp4" />
      </video> 
    </Box>
  )
}
