import React, { Component } from 'react';
import { Pagination, Stack } from '@mui/material';

type Props = {
  count: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

type State = {
  currentPage: number;
}

export class Paginator extends Component<Props, State> {

  constructor (props: Props, state: State) {
    super(props, state);
    this.state = { currentPage: this.props.currentPage }
  }

  onChange (event: React.ChangeEvent<unknown>, page: number) {
    if (typeof this.props.onPageChange === 'function') {
      this.props.onPageChange(page);
    }
    this.setState({
      ...this.state, currentPage: page });
  }

  render () {
    return (
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={Math.ceil(this.props.count / this.props.pageSize)}
          page={this.state.currentPage}
          onChange={this.onChange.bind(this)}
          shape="rounded" />
      </Stack>
    )
  }
}
