import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Comment } from '../Comment/Comment';
import { CircularProgress, Stack } from '@mui/material';
import { CrmApiClientFactory } from '../../services/crm/client';
import { IComment } from '../../services/crm/types';
import { useNavigate } from 'react-router-dom';
import { AddComment } from '../AddComment';
import { itsonMuiTheme } from '../../theme/ITSONMUITheme';

type Props = {
  type: string;
  id: string;
  close: () => void;
}

type ScrollProps = {
  window?: () => Window;
  children: React.ReactElement;
}

export const Comments = (props: Props) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [comments, setComments] = useState<IComment[]>([]);
  const crmApiClient = CrmApiClientFactory(navigate);

  const loadComments = () => {
    setLoading(true);
    crmApiClient.getComments(props.type, props.id)
      .then((data) => {
        setComments(data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setComments([]);
        setLoading(false);
      });
  };

  const updateComment = (id: string, content: string, rate: number) => {
    const comment = comments.find(c => c.id === id)
    if (comment) {
      comment.content = content;
      comment.rate = rate;
    }
  }

  useEffect(() => {
    loadComments();
  }, []);

  const onCommentCreated = () => {
    loadComments();
  }

  return (
    <Stack>
      <Box sx={{
        maxHeight: '40vh',
        overflow: 'auto',
        transition: 'none',
        backgroundColor: itsonMuiTheme.palette.background.default,
        boxShadow: 'none',
        backgroundImage: 'none',
      }}>
        {isLoading ? <Box sx={{ display: 'flex', paddingTop: 1, paddingBottom: 2 }}><CircularProgress sx={{margin: '0 auto', marginTop: 1}} /></Box>: ''}
        {!comments || comments.length === 0 && !isLoading ? <Box sx={{ display: 'flex', paddingLeft: 2, paddingTop: 3, paddingBottom: 3 }}>No comments found</Box> : (
          comments.map((comment: IComment) =>
            <Comment updateComment={updateComment} key={comment.id} comment={comment} />)
        )}
      </Box>
      <AddComment type={props.type} close={props.close} id={props.id} onCommentCreated={onCommentCreated} />
    </Stack>
  );
}
