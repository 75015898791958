import React, { Component } from 'react';
import VideoJS from '../VideoJS/VideoJS';
import { Alert, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { CrmApiClient, CrmApiClientFactory } from '../../services/crm/client';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useWidth } from '../../hooks/useWidth';
import { Videocam } from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import { IStream } from '../../services/api/types';
import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';

type Props = {
  stream: IStream;
  navigate: NavigateFunction;
  breakpoint: string;
  streams: any;
  index: number;
  height: number;
  width: string;
  align: string;
  cols: number;
  ref: any;
  key: any;
}

type State = {
  isRecording: boolean;
  id: number;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

class LiveVideoComponent extends Component<Props, State> {

  videoJsOptions: any = {};
  crmApiClient: CrmApiClient;

  constructor (props: Props, state: State) {
    super(props, state);
    this.crmApiClient = CrmApiClientFactory(props.navigate);
    this.state = {
      isRecording: props.stream.isRecording,
      id: Date.now(),
    };
    this.initializeVideoPlayer();
  }

  componentWillUnmount () {
    // workaround for duplicated events
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const liveVideos = (window.liveVideos = window.liveVideos || {});
    liveVideos['stream' + this.state.id] = true;
    document.removeEventListener('keydown', this.onKeydown.bind(this));
  }

  componentDidMount () {
    document.addEventListener('keydown', this.onKeydown.bind(this));

    if (this.props.stream.isRecording) {
      this.refreshStream();
    }
  }

  initializeVideoPlayer () {
    if (!this.props.stream || !this.props.stream.streamId) {
      console.log('invalid stream id', JSON.stringify(this.props.stream));
      Sentry.captureMessage('invalid stream id: ' + JSON.stringify(this.props.stream));
    }
    const streamId = this.props.stream.streamId;
    const websocketUrl = `${process.env.REACT_APP_ANT_MEDIA_WEBSOCKET}/${streamId}.webrtc`;
    const iceServers = [ { 'urls': 'stun:stun1.l.google.com:19302' } ];

    this.videoJsOptions = {
      autoplay: 'muted',
      controls: true,
      responsive: true,
      playsinline: true,
      fluid: true,
      muted: true,
      aspectRatio: this.props.stream.orientation === 'landscape' ? '16:9' : '9:16',
      width: '100%',
      fill: true,
      html5: {},
      sources: [ {
        src: `${websocketUrl}?token=${process.env.REACT_APP_ANT_MEDIA_SECRET_KEY}`,
        iceServers: JSON.stringify(iceServers),
      } ],
    };
  }

  onKeydown (event: KeyboardEvent) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const liveVideos = window.liveVideos || {};
    if (liveVideos['stream' + this.state.id]) {
      return;
    }
    const keyEqual = (this.props.stream.title && this.props.stream.title.toLowerCase() === event.key) || event.code == 'Space';
    if (keyEqual && this.props.stream.canRecording) {
      if (this.state.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    }
  }

  handlePlayerReady (player: any) {
    player.on('waiting', () => {
      console.log('player is waiting');
    });
    player.on('dispose', () => {
      console.log('player will dispose');
    });
    player.on('ant-error', (event: any, errors: any) => {
      console.log('ant-error', errors);
      Sentry.captureMessage(JSON.stringify(errors));
    });
    player.volume(0);
    // player.play();
  }

  startRecording ()  {
    this.crmApiClient.startRecording(this.props.stream.id)
      .then((response: any) => {
        this.refreshStream.call(this);
        this.setState({
          ...this.state,
          isRecording: true,
        });
      })
      .catch((error: any) => {
        console.log(error);
        this.setState({
          ...this.state,
          isRecording: false,
        });
      });
  }

  stopRecording () {
    this.crmApiClient.stopRecording(this.props.stream.id)
      .then((response: any) => {
        this.setState({
          ...this.state,
          isRecording: false,
        });
      })
      .catch((error: any) => {
        console.log(error);
        this.setState({
          ...this.state,
          isRecording: true,
        });
      });
  }

  refreshStream () {
    this.crmApiClient.getStream(this.props.stream.id)
      .then((streamResponse: IStream) => {
        this.setState({
          ...this.state, isRecording: streamResponse.isRecording})
        if (streamResponse.isRecording) {
          setTimeout(this.refreshStream.bind(this), 5000);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  render () {
    let gridItem = 4;
    let rows = 1;
    let cols = 1;
    const streamsCount = Object.values(this.props.streams).filter(v => v).length;
    if (this.props.breakpoint === 'xs') {
      gridItem = 12;
      cols = 1;
      rows = 2;
    } else {
      switch (this.props.breakpoint) {
        case 'xl':
        case 'lg':
        case 'md':
        case 'sm':
          if (streamsCount === 1) {
            gridItem = 12;
          } else {
            gridItem = 6;
          }
          break;
      }
      const isEven = streamsCount % 2 === 0;
      // console.log('$$$$ streamsCount', streamsCount)
      if (streamsCount === 1) {
        rows = 1;
        cols = 1;
      } else {
        rows = 2;
        cols = 2;
      }
    }

    let action = null
    if (this.props.stream.canRecording) {
      if (!this.state.isRecording) {
        action = (
          <Tooltip title={"Start recording"}>
            <IconButton onClick={this.startRecording.bind(this)} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
              <Videocam />
            </IconButton>
          </Tooltip>
        )
      } else {
        action = (
          <Tooltip title={"Stop recording"}>
            <IconButton onClick={this.stopRecording.bind(this)} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
              <svg fill={'currentColor'} viewBox={'0 0 24 24'} style={{width: '1em', height: '1em', color: 'red'}}>
                <circle cx={16} cy={12} r={4} />
              </svg>
              <span style={{ fontSize: '10pt', color: this.props.stream.color}}>REC</span>
            </IconButton>
          </Tooltip>
        )
      }
    }
    try {
      const sx: any = { position: 'relative' };
      if (this.props.width) {
        sx.width = this.props.width;
      }
      return (
        <Box sx={sx}>
          <Toolbar variant={'dense'} disableGutters={true}
                   sx={{
                     width: '100%',
                     flexGrow: 1,
                     paddingLeft: 1,
                     paddingRight: 0,
                     paddingTop: 1,
                     paddingBottom: 0,
                     position: 'absolute',
                     top: 0,
                     zIndex: 1000,
                     minHeight: 0,
                     left: 0}}>
            <Typography color={this.props.stream.color} fontSize={'1rem'}>
              { this.props.stream.title }
            </Typography>
            <Box sx={{ position: 'absolute', right: 0 }}>
              {action}
            </Box>
          </Toolbar>
          <VideoJS
            orientation={this.props.stream.orientation}
            options={this.videoJsOptions}
            onReady={this.handlePlayerReady.bind(this)}/>
        </Box>
        // </ImageListItem>
        // </Grid>
      )
    } catch (e) {
      return (
        <Grid item xs={12}>
          <Alert variant="outlined" severity="error">
            Could not establish connection
          </Alert>
        </Grid>
      )
    }
  }
}

export const LiveVideo = (props: any) => {
  const navigate = useNavigate();
  const breakpoint = useWidth();
  return (<LiveVideoComponent  {...props}
      breakpoint={breakpoint}
      navigate={navigate} />)
}
