import { useCallback, useEffect, useState } from 'react';
import { IStream } from '../services/api/types';

export type UseStreams = {
  activeStreams: any;
  toggleStream: (stream: IStream) => void;
  enableStreams: (streams: IStream[]) => void;
};

export enum EventType {
  TOGGLE_STREAM = 'toggleStream',
  ENABLE_STREAMS = 'enableStreams',
}

const read = () => {
  let storedActiveStreams = {};
  try {
    storedActiveStreams = JSON.parse(localStorage.getItem('as') || '') || {};
  } catch {
    storedActiveStreams = {};
  }
  return storedActiveStreams;
};

const persist = (activeStreams: any) => {
  localStorage.setItem('as', JSON.stringify(activeStreams));
};

export const useStreams = (): UseStreams => {
  const [activeStreams, setActiveStreams] = useState<any>(read());

  const onToggleStream = useCallback((event: CustomEvent<IStream>): void => {
    const stream = event.detail;
    const activeStreams_ = {...activeStreams};
    activeStreams_[stream.id] = !activeStreams_[stream.id];
    setActiveStreams(activeStreams_);
    persist(activeStreams_);
  }, [activeStreams]) as EventListenerOrEventListenerObject;

  const onEnableStreams = useCallback((event: CustomEvent<IStream[]>): void => {
    const streams = event.detail;
    console.log('onEnableStreams', streams);
    const activeStreams_ = Object.assign({}, ...streams.map(stream => {
      return {
        [stream.id]: true,
      }
    }));
    setActiveStreams(activeStreams_);
    persist(activeStreams_);
  }, [activeStreams]) as EventListenerOrEventListenerObject;

  useEffect(() => {
    document.addEventListener(EventType.TOGGLE_STREAM, onToggleStream);
    return () => {
      document.removeEventListener(EventType.TOGGLE_STREAM, onToggleStream, false);
    }
  }, [onToggleStream]);

  useEffect(() => {
    document.addEventListener(EventType.ENABLE_STREAMS, onEnableStreams);
    return () => {
      document.removeEventListener(EventType.ENABLE_STREAMS, onEnableStreams, false);
    }
  }, [onEnableStreams]);

  return {
    activeStreams: activeStreams,
    toggleStream: (options) => document.dispatchEvent(
      new CustomEvent(EventType.TOGGLE_STREAM, {
        detail: options,
      })
    ),
    enableStreams: (options) => document.dispatchEvent(
      new CustomEvent(EventType.ENABLE_STREAMS, {
        detail: options,
      })
    ),
  };
};
