import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';

interface Props {
  children: ReactElement;
}

export function PrivateRoute ({ children }: Props): ReactElement {
  const { savedValue: session } = useLocalStorage<string>('session');
  if (!session) {
    try {
      const sessData = JSON.parse(session);
      if (sessData.guest) {
        return <Navigate to="/login" replace />;
      }
    } catch (e) {
      console.log(e);
    }
    return <Navigate to="/login" replace />;
  }

  return children;
}
