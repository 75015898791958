import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { ToastContainer } from 'react-toastify';
import { CrmApiClientFactory } from '../../services/crm/client';
import { Streams } from '../../services/streams/streams';
import { IStream } from '../../services/api/types';
import { useStreams } from '../../hooks/useStreams';
import { IUser } from '../../services/crm/types';
import Launcher from '../../components/Chat/Launcher/Launcher';
import { ChatPopup } from '../../components/Chat/ChatPopup/ChatPopup';
import { ChatService } from '../../services/chat/chat';
import { Header } from '../../components/Header';

export function Layout () {
  const [streams, setStreams] = useState([] as IStream[] || null);
  const [chatVisible, setChatVisibility] = useState(false);
  const [chatRendered, setChatRendered] = useState(true);
  const activeStreamsSwitch = useStreams();
  const navigate = useNavigate();
  const location = useLocation();
  const crmApiClient = CrmApiClientFactory(navigate);
  const { savedValue } = useLocalStorage<IUser>('user');
  const [user, setUser] = useState({} as IUser);
  const crmClientApi = CrmApiClientFactory(navigate);
  const streamsHelper = new Streams(crmClientApi);

  const initializeChat = () => {
    const chatService = ChatService.getInstance(crmApiClient);
    chatService.createSession()
      .then(() => {
        chatService.watch().then();
        setInterval(() => {
          chatService.watch().then();
        }, 21 * 1000);
      });
  };

  useEffect(() => {
    if (!savedValue) {
      setChatRendered(false);
      crmApiClient.getIdentity().then((user: IUser) => {
        localStorage.setItem('user', JSON.stringify(user));
        initializeChat()
        setUser(user);
        setChatRendered(true);
      });
    } else {
      initializeChat();
      setUser(savedValue);
    }

    const identityInterval = setInterval(() => {
      crmApiClient.getIdentity().then();
    }, 60 * 1000);

    return () => {
      if (identityInterval) {
        clearInterval(identityInterval);
      }
    };
  }, []);

  const isLiveView = location.pathname === '/live';
  const availableStreams = streams.filter(s => isLiveView ? s.isAlive : true);

  const onChatToggle = () => {
    setChatVisibility(!chatVisible);
    setTimeout(() => {
      const bottom = document.getElementById('conversation-bottom');
      if (bottom !== null) {
        bottom.scrollIntoView(false);
      }
    }, 100);
  };

  useEffect(() => {
    if (!streams.length) {
      streamsHelper.fetch().then((streamsList: IStream[]) => {
        setStreams(streamsList);
        activeStreamsSwitch.enableStreams(streamsList);
      });
    } else {
      setStreams(streams);
    }
  }, [])

  return (
    <Box sx={{ flexGrow: '1' }}>
      <CssBaseline />
      <Header availableStreams={availableStreams} openChat={onChatToggle} />
      <ToastContainer />
      <Box component="main" sx={{ flexGrow: 1, paddingTop: 0, paddingLeft: 0, paddingRight: 0, marginTop: 8 }}>
        <Outlet />
      </Box>
      { chatRendered && user ? (
        <ChatPopup visible={chatVisible} user={user} guest={false} />
      ) : '' }
      { chatRendered && user ? (
        <Launcher showBadge={true} toggle={onChatToggle} />
      ) : '' }
    </Box>
  )
}
